import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

// Custom Cell Editor
export default class CustomLargeTextCellEditor implements ICellEditorComp {
  private eGui!: HTMLElement;
  private eTextarea!: HTMLTextAreaElement;
  private eDiv!: HTMLElement;

  // Initialize the editor
  init(params: ICellEditorParams): void {
    let originalValue = params.value;

    // Create a div container
    this.eGui = document.createElement('div');
    this.eGui.classList.add('custom-editor-container');

    // Create a textarea
    this.eTextarea = document.createElement('textarea');
    this.eTextarea.value = params.value || ''; // Set initial value
    this.eTextarea.rows = 10;
    this.eTextarea.cols = 40;

    this.eTextarea.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.shiftKey) {
        // Shift+Enter detected, insert a new line
        const { selectionStart, selectionEnd } = this.eTextarea;
        const textValue = this.eTextarea.value;
        this.eTextarea.value = textValue.slice(0, selectionStart) + '\n' + textValue.slice(selectionEnd);

        // Move cursor after the inserted new line
        this.eTextarea.selectionStart = this.eTextarea.selectionEnd = selectionStart + 1;

        // Prevent the default behavior (preventing ag-Grid from closing the editor)
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        this.eTextarea.value = originalValue;

        params.stopEditing(false);
      }
    });

    // Helper text for newline
    this.eDiv = document.createElement('div');
    this.eDiv.innerText = 'Use Shift+Enter to create a new line. Use Enter to confirm.';
    this.eDiv.classList.add('custom-editor-info');

    // Append textarea and div to the container
    this.eGui.appendChild(this.eTextarea);
    this.eGui.appendChild(this.eDiv);
  }

  // Return the GUI for the editor
  getGui(): HTMLElement {
    return this.eGui;
  }

  // Focus on the textarea after the GUI is attached
  afterGuiAttached?(): void {
    this.eTextarea.focus();
  }

  // Return the value from the editor
  getValue(): any {
    return this.eTextarea.value;
  }

  // Optional: cleanup when the editor is destroyed
  destroy?(): void {
    // Cleanup if needed
  }

  // Determine if the editor should appear in a popup
  isPopup(): boolean {
    return true;
  }
}

// Column Defi
