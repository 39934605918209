import { findParameterAsync } from '@/utils/tableau';
import { Worksheet, ZoneVisibilityType } from '@tableau/extensions-api-types';
import type { RowNode } from 'ag-grid-community';
import formatDate from '@/utils/dateFormat';
import { unique } from '../utils/functional';
import { Tooltip } from './Tooltip';

interface BaseAction {
  type: 'filter' | 'parameter' | 'highlight' | 'container';
  source?: string; // the column/field that we get the values from
  target?: string; // the name of the parameter/worksheet that we update
  dateFormat: string;
}

export type FilterClearType = 'do-nothing' | 'clear-filter' | 'filter-all';
export interface FilterAction extends BaseAction {
  type: 'filter';
  afterClear?: FilterClearType;
}

interface ParameterAction extends BaseAction {
  type: 'parameter';
  allowMultipleValues?: boolean;
  delimiter: string;
}

interface HighlightAction extends BaseAction {
  type: 'highlight';
}

interface ContainerAction extends BaseAction {
  type: 'container';
}

export type GridAction = FilterAction | ParameterAction | HighlightAction | ContainerAction;

const FilterUpdateType = window.tableau.FilterUpdateType;
const SelectionUpdateType = window.tableau.SelectionUpdateType;

/**
 * applyActions a list of actions
 */
export function applyActions(
  actions: GridAction[],
  rows: RowNode[],
  ignore: { worksheet?: string; parameter?: string } = {}
): string[] {
  const extension = window.tableau.extensions;
  const isVizExtension = !!extension.worksheetContent;
  if (isVizExtension) {
    applyMarksSelection(rows);
    return [];
  }
  // this updatedParameters list is not my favorite thing...
  // it is leaking logic from applyParameterAction into applyActions
  let updatedParameters: string[] = [];

  for (let action of actions) {
    if (shouldSkipAction(action, ignore)) continue;

    let values = unique(
      rows.map((row) => {
        if (row.group && row.field === action.source) return row.key;
        if (!row.group) {
          // @ts-ignore source is not undefined here
          let value = row.data[action.source];
          if (!value) {
            // if the source has a dot in it we try to replace it with an underscore (done in mapping.js: getField())
            // @ts-ignore source is not undefined here
            const sourceNameWithUnderscore = action.source.replace(/\./g, '_');
            value = row.data[sourceNameWithUnderscore];
          }
          return value;
        }
      })
    );

    switch (action.type) {
      case 'filter':
        applyFilterAction(action, values);
        break;

      case 'parameter':
        applyParameterAction(action, values);
        if (action.allowMultipleValues || values.length === 1) {
          if (action.target) updatedParameters.push(action.target);
        }
        break;

      case 'container':
        applyContainerAction(action, values);
        break;

      case 'highlight':
        applyHighlightAction(action, values);
        break;

      default:
        // @ts-ignore
        console.error('unknown action type ' + action.type);
    }
  }

  return updatedParameters;
}

function applyMarksSelection(rows: RowNode[]) {
  const worksheet = window.tableau.extensions.worksheetContent?.worksheet;
  if (!worksheet) {
    console.error('No worksheet found');
    return;
  }

  if (!rows.length) {
    worksheet.selectTuplesAsync([], window.tableau.SelectOptions.Simple);
    return;
  }

  let clickedTuples: number[] = [];
  rows.forEach((row) => {
    if (row.group) {
      return;
    }
    row.data['index'] && clickedTuples.push(row.data['index']);
  });

  worksheet
    .selectTuplesAsync(clickedTuples, window.tableau.SelectOptions.Simple, {
      tooltipAnchorPoint: { x: Tooltip.mousePosition.x, y: Tooltip.mousePosition.y },
    })
    .then()
    .catch((error: any) => console.error('Failed to select because of: ', error));

  return;

  /**
   * @type {Array<{ fieldName: string, value: Array<string> }>}
   */
  // const markSelections = [];

  /**
   *
   * @param {string} fieldName
   * @param {string} value
   * @returns {void}
   */
  // function updateColumn(fieldName, value) {
  //   if (typeof value !== 'string') return; // ignore numbers
  //   const existingColumn = markSelections.find((item) => item.fieldName === fieldName);
  //   if (existingColumn) {
  //     existingColumn.value.push(value);
  //   } else {
  //     markSelections.push({ fieldName, value: [value] });
  //   }
  // }

  // rows.forEach((row) => {
  //   if (row.group) {
  //     updateColumn(row.field, row.key);
  //   } else {
  //     for (let column in row.data) {
  //       updateColumn(column, row.data[column]);
  //     }
  //   }
  // });

  // worksheet.selectMarksByValueAsync(markSelections, window.tableau.SelectionUpdateType.Replace);
  // return;
}

function shouldSkipAction(action: GridAction, ignore: { worksheet?: string; parameter?: string }): boolean {
  if (!action.source || !action.target) return true;

  // this is unfortunate...
  switch (action.type) {
    case 'filter':
    case 'highlight':
      return ignore.worksheet === action.target;

    case 'parameter':
      return ignore.parameter === action.target;
  }

  return false;
}

function applyFilterAction(action: FilterAction, values: any[]) {
  if (!action.source) return;

  let worksheet = findWorksheet(action.target);
  if (!worksheet) return;

  if (values.length) {
    if (action.dateFormat) {
      let newValues: string[] = [];
      values.forEach((value) => {
        if (value instanceof Date) {
          newValues.push(formatDate(value, action.dateFormat));
        }
      });
      if (newValues.length) {
        values = newValues;
      }
    }
    // @todo: we should use DataValue.value to filter currently there
    // is no way to access this value. This breaks filtering on date columns.
    worksheet.applyFilterAsync(action.source, values, FilterUpdateType.Replace);
    return;
  }

  switch (action.afterClear) {
    case 'do-nothing':
      return;

    case 'filter-all':
      worksheet.applyFilterAsync(action.source, [], FilterUpdateType.Replace);
      return;

    case 'clear-filter':
    default:
      worksheet.clearFilterAsync(action.source);
      return;
  }
}

async function applyParameterAction(action: ParameterAction, values: any[]) {
  if (action.allowMultipleValues !== true && values.length !== 1) return;

  let parameter = await findParameter(action.target);
  if (!parameter) return;

  if (action.dateFormat) {
    let newValues: string[] = [];
    // dateformat so lets parse the dates
    values.forEach((value) => {
      newValues.push(formatDate(new Date(value), action.dateFormat));
    });
    values = newValues;
  }

  let value = action.allowMultipleValues ? values.join(action.delimiter) : values[0];

  // @todo: this depends on parameter.dataType...
  parameter.changeValueAsync(value);
}

function applyHighlightAction(action: HighlightAction, values: any[]) {
  if (!action.source) return;

  let worksheet = findWorksheet(action.target);
  if (!worksheet) return;

  if (action.dateFormat) {
    let newValues: string[] = [];
    // dateformat so lets parse the dates
    values.forEach((value) => {
      newValues.push(formatDate(new Date(value), action.dateFormat));
    });
    values = newValues;
  }

  worksheet.selectMarksByValueAsync([{ fieldName: action.source, value: values }], SelectionUpdateType.Replace);
}

function applyContainerAction(action: ContainerAction, values: any[]) {
  if (!action.target) return;
  let zoneVisibilityMap: Map<string, ZoneVisibilityType> = new Map();
  if (values.length) {
    zoneVisibilityMap.set(action.target, 'show');
  } else {
    zoneVisibilityMap.set(action.target, 'hide');
  }
  try {
    !!window.tableau.extensions.dashboardContent &&
      window.tableau.extensions.dashboardContent.dashboard
        .setZoneVisibilityAsync(zoneVisibilityMap)
        .catch((err: any) => {
          console.error('Error', err);
        });
  } catch (err) {
    console.error(err);
  }
}

/**
 * find the worksheet with the specified name
 */
function findWorksheet(name: string | undefined) {
  if (!name) return;
  if (!!window.tableau.extensions.dashboardContent) {
    let dashboard = window.tableau.extensions.dashboardContent.dashboard;
    return dashboard.worksheets.find((sheet: Worksheet) => sheet.name === name);
  }
  // TODO: do we return window.tableau.extensions.worksheetContent.worksheet
}

/**
 * find the worksheet with the specified name
 */
function findParameter(name: string | undefined) {
  if (!name) return;
  return findParameterAsync(name);
}
