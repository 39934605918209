import Combobox from '@/components/combobox';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import BiMap from '@/utils/BiMap';
import { cn } from '@/utils/cn';
import { endOfWeek, format, startOfWeek, subDays } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { FiChevronDown, FiFilter } from 'react-icons/fi';
import { DefaultAuditFilter } from '.';
import { useWBEStore } from '../store';
import MenuButton from '@/components/ui/menuButton';

type AuditFilterProps = {
  filter: DefaultAuditFilter | null;
  setFilter: (filter: DefaultAuditFilter | null) => void;
  currentUser: string;
  usernames: string[];
};

const defaultFilter = {
  column: '',
  row: '',
  status: null,
  startDate: null,
  endDate: null,
  username: '',
};

type FilterCategory = 'quickFilters' | 'status' | 'column' | 'date' | 'user';

const AuditFilter: React.FC<AuditFilterProps> = ({ filter, setFilter, currentUser, usernames }) => {
  const nameMap = useWBEStore((state) => state.nameMap);

  const [internalFilter, setInternalFilter] = useState<DefaultAuditFilter>({
    ...defaultFilter,
    ...filter,
  });
  const [activeCategory, setActiveCategory] = useState<FilterCategory>('quickFilters');
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const handleCategoryHover = (categoryKey: FilterCategory) => {
    setActiveCategory(categoryKey);
  };

  const handleFilterClick = (quickFilter: Partial<DefaultAuditFilter>) => {
    setInternalFilter((prevFilter) => ({
      ...prevFilter,
      ...quickFilter,
    }));
  };

  const handleReset = () => {
    setInternalFilter({ column: '', row: '', status: null, startDate: null, endDate: null, username: '' });
    setFilter(null);
    setShowFilter(false);
  };

  const handleApply = () => {
    setFilter(internalFilter);
    setShowFilter(false);
  };

  const categories: {
    name: string;
    key: FilterCategory;
    component: JSX.Element;
    active: boolean;
  }[] = [
    // { // it does not work..
    //   name: 'Quick Filters',
    //   key: 'quickFilters',
    //   component: (
    //     <QuickFiltersComponent
    //       currentFilter={internalFilter}
    //       onFilterClick={handleFilterClick}
    //       currentUser={currentUser}
    //     />
    //   ),
    //   active: false,
    // },
    {
      name: 'Status',
      key: 'status',
      component: (
        <StatusComponent
          currentFilter={internalFilter}
          onFilterClick={handleFilterClick}
        />
      ),
      active: !!filter && !!filter.status,
    },
    {
      name: 'Column',
      key: 'column',
      component: (
        <ColumnComponent
          currentFilter={internalFilter}
          onFilterClick={handleFilterClick}
          nameMap={nameMap}
        />
      ),
      active: !!filter && !!filter.column,
    },
    {
      name: 'Date',
      key: 'date',
      component: (
        <DateComponent
          currentFilter={internalFilter}
          onFilterClick={handleFilterClick}
        />
      ),
      active: !!filter && (!!filter.startDate || !!filter.endDate),
    },
    {
      name: 'User',
      key: 'user',
      component: (
        <UserComponent
          currentFilter={internalFilter}
          onFilterClick={handleFilterClick}
          usernames={usernames}
        />
      ),
      active: !!filter && !!filter.username,
    },
  ];

  const renderRightComponent = () => {
    const selectedCategory = categories.find((category) => category.key === activeCategory);
    return selectedCategory ? (
      selectedCategory.component
    ) : (
      <QuickFiltersComponent
        currentFilter={internalFilter}
        currentUser={currentUser}
        onFilterClick={handleFilterClick}
      />
    );
  };

  const filterCount = useMemo(() => {
    let count = 0;

    if (!filter) return count;

    if (filter.column || filter.row) count++;
    if (filter.status) count++;
    if (filter.startDate || filter.endDate) count++;
    if (filter.username) count++;

    return count;
  }, [filter]);

  return (
    <Popover
      open={showFilter}
      onOpenChange={setShowFilter}>
      <PopoverTrigger>
        <MenuButton
          icon={<FiFilter size={15} />}
          badge={filterCount}
          title="Filter audits"
        />
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="mt-2 h-96 w-full rounded-md border border-gray-300 p-0">
        <div className="flex h-full items-stretch gap-x-2 overflow-hidden rounded-lg border shadow-lg">
          <div className="flex w-48 flex-col gap-y-1 border-r border-gray-400 bg-white py-4">
            <h3 className="font-semibol mb-3 px-4 text-lg text-gray-700">Filters</h3>
            {categories.map((category) => (
              <div
                key={category.key}
                className={`flex cursor-pointer items-center justify-between gap-x-2 px-4 py-2 transition-all duration-200 hover:bg-accent ${
                  activeCategory === category.key ? 'bg-accent' : ''
                }`}
                onMouseEnter={() => handleCategoryHover(category.key)}>
                {category.active && <Badge>!</Badge>}
                <span className="flex-1 font-medium text-gray-700">{category.name}</span>
                <FiChevronDown
                  className={`transform text-gray-600 transition-transform duration-200 ${
                    activeCategory === category.key ? '-rotate-90' : ''
                  }`}
                />
              </div>
            ))}
          </div>
          <div className="flex w-80 flex-col gap-y-4 bg-white px-2 py-4">
            <h3 className="px-2 text-lg font-semibold text-gray-700">
              {categories.find((cat) => cat.key === activeCategory)?.name || 'Quick Filters'}
            </h3>
            <div className="z-10 flex flex-grow overflow-hidden">{renderRightComponent()}</div>
            <div className="flex items-center justify-between">
              <Button
                variant="outline"
                onClick={handleReset}>
                Reset
              </Button>
              <Button onClick={handleApply}>Apply</Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

type StatusComponentProps = {
  onFilterClick: (filterValue: Partial<DefaultAuditFilter>) => void;
  currentFilter: DefaultAuditFilter;
};

const StatusComponent = ({ onFilterClick, currentFilter }: StatusComponentProps) => {
  const statusFilters = [
    { label: 'All', filter: { status: null }, active: (filter) => !filter.status, remove: { status: null } },
    {
      label: 'Updated',
      filter: { status: 'UPDATED' },
      active: (filter) => filter.status === 'UPDATED',
      remove: { status: null },
    },
    {
      label: 'Deleted',
      filter: { status: 'DELETED' },
      active: (filter) => filter.status === 'DELETED',
      remove: { status: null },
    },
  ];

  function onStatusFilterClick(statusFilter) {
    statusFilter.active(currentFilter) ? onFilterClick(statusFilter.remove) : onFilterClick(statusFilter.filter);
  }

  return (
    <div className="flex flex-col gap-2">
      {statusFilters.map((statusFilter) => (
        <Label
          key={statusFilter.label}
          className={`cursor-pointer rounded-lg px-4 py-1 font-medium text-gray-700 transition-all duration-200 ${
            statusFilter.active(currentFilter) ? 'bg-accent' : 'hover:bg-accent'
          }`}
          onClick={() => onStatusFilterClick(statusFilter)}>
          {statusFilter.label}
        </Label>
      ))}
    </div>
  );
};

type ColumnComponentProps = {
  currentFilter: DefaultAuditFilter;
  onFilterClick: (filterValue: Partial<DefaultAuditFilter>) => void;
  nameMap: BiMap;
};

const ColumnComponent = ({ nameMap, currentFilter, onFilterClick }: ColumnComponentProps) => {
  const columnNames = nameMap.getValuesForwardMap().map((name) => ({ value: name, label: name }));

  return (
    <div className="h-full w-full overflow-hidden px-2">
      <Combobox
        defaultValue={currentFilter.column ? { value: currentFilter.column, label: currentFilter.column } : null}
        onChange={(option) => onFilterClick({ column: option?.value })}
        options={columnNames}
        placeholder="Select Column"
        getOptionLabel={(option) => option?.label || ''}
        getOptionValue={(option) => option?.value || ''}
        className="w-full p-0 text-sm"
        maxMenuHeight={130}
        isClearable
      />
    </div>
  );
};

type UserComponentProps = {
  usernames: string[];
  currentFilter: DefaultAuditFilter;
  onFilterClick: (filterValue: Partial<DefaultAuditFilter>) => void;
};

const UserComponent = ({ usernames, currentFilter, onFilterClick }: UserComponentProps) => {
  const usernameOptions = usernames.map((username) => ({ value: username, label: username }));
  return (
    <div className="h-full w-full overflow-hidden px-2">
      <Combobox
        defaultValue={currentFilter.column ? { value: currentFilter.column, label: currentFilter.column } : null}
        value={currentFilter.username ? { value: currentFilter.username, label: currentFilter.username } : null}
        onChange={(option) => onFilterClick({ username: option?.value })}
        options={usernameOptions}
        placeholder="Select Column"
        getOptionLabel={(option) => option?.label || ''}
        getOptionValue={(option) => option?.value || ''}
        className="w-full p-0 text-sm"
        maxMenuHeight={130}
        isClearable
      />
    </div>
  );
};

const DateComponent = ({ currentFilter, onFilterClick }) => {
  const startDate = currentFilter.startDate;
  const endDate = currentFilter.endDate;
  const date = startDate || endDate ? { from: startDate, to: endDate } : undefined;

  function setDate(date: DateRange | undefined) {
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    if (!date) {
      onFilterClick({ startDate: null, endDate: null });
      return;
    }

    if (date.from) {
      startDate = date.from;
    }

    if (date.to) {
      endDate = date.to;
    }
    onFilterClick({ startDate, endDate });
  }

  return (
    <div className="grid w-full gap-2 px-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn('w-full justify-start text-left font-normal', !date && 'text-muted-foreground')}>
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0"
          align="end">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

type QuickFiltersComponentProps = {
  onFilterClick: (filterValue: Partial<DefaultAuditFilter>) => void;
  currentUser: string;
  currentFilter: DefaultAuditFilter;
};

const QuickFiltersComponent: React.FC<QuickFiltersComponentProps> = ({ onFilterClick, currentUser, currentFilter }) => {
  const quickFilters: {
    label: string;
    filter: Partial<DefaultAuditFilter>;
    active: (filter: DefaultAuditFilter) => boolean;
    removeFilter: Partial<DefaultAuditFilter>;
  }[] = [
    {
      label: 'Updated',
      filter: { status: 'UPDATED' },
      active: (filter) => filter.status === 'UPDATED',
      removeFilter: { status: null },
    },
    {
      label: 'Deleted',
      filter: { status: 'DELETED' },
      active: (filter) => filter.status === 'DELETED',
      removeFilter: { status: null },
    },
    {
      label: 'Made by me',
      filter: { username: currentUser },
      active: (filter) => filter.username === currentUser,
      removeFilter: { username: '' },
    },

    {
      label: 'Today',
      filter: {
        startDate: new Date(),
        endDate: new Date(),
      },
      active: (filter) => {
        return (
          !!filter.startDate &&
          !!filter.endDate &&
          filter.startDate.toDateString() === new Date().toDateString() &&
          filter.endDate.toDateString() === new Date().toDateString()
        );
      },
      removeFilter: { startDate: null, endDate: null },
    },
    {
      label: 'Yesterday',
      filter: {
        startDate: subDays(new Date(), 1),
        endDate: subDays(new Date(), 1),
      },
      active: (filter) => {
        const yesterday = subDays(new Date(), 1);
        return (
          !!filter.startDate &&
          !!filter.endDate &&
          filter.startDate.toDateString() === yesterday.toDateString() &&
          filter.endDate.toDateString() === yesterday.toDateString()
        );
      },
      removeFilter: { startDate: null, endDate: null },
    },
    {
      label: 'This Week',
      filter: {
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }), // Monday as the start of the week
        endDate: new Date(), // Current date as end date
      },
      active: (filter) => {
        const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
        const today = new Date();
        return (
          !!filter.startDate &&
          !!filter.endDate &&
          filter.startDate.toDateString() === startOfCurrentWeek.toDateString() &&
          filter.endDate.toDateString() === today.toDateString()
        );
      },
      removeFilter: { startDate: null, endDate: null },
    },
    {
      label: 'Last Week',
      filter: {
        startDate: startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
        endDate: endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
      },
      active: (filter) => {
        const startOfLastWeek = startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 });
        const endOfLastWeek = endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 });
        return (
          !!filter.startDate &&
          !!filter.endDate &&
          filter.startDate.toDateString() === startOfLastWeek.toDateString() &&
          filter.endDate.toDateString() === endOfLastWeek.toDateString()
        );
      },
      removeFilter: { startDate: null, endDate: null },
    },
  ];

  function onQuickFilterClick(quickFilter) {
    quickFilter.active(currentFilter) ? onFilterClick(quickFilter.removeFilter) : onFilterClick(quickFilter.filter);
  }

  return (
    <div className="flex flex-wrap gap-2">
      <div className="flex flex-col gap-2">
        {quickFilters.slice(0, 4).map((quickFilter) => (
          <Label
            key={quickFilter.label}
            className={`cursor-pointer rounded-lg px-4 py-1 font-medium text-gray-700 transition-all duration-200 ${
              quickFilter.active(currentFilter) ? 'bg-accent' : 'hover:bg-accent'
            }`}
            onClick={() => onQuickFilterClick(quickFilter)}>
            {quickFilter.label}
          </Label>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        {quickFilters.slice(4).map((quickFilter) => (
          <Label
            key={quickFilter.label}
            className={`cursor-pointer rounded-lg px-4 py-1 font-medium text-gray-700 transition-all duration-200 ${
              quickFilter.active(currentFilter) ? 'bg-accent' : 'hover:bg-accent'
            }`}
            onClick={() => onQuickFilterClick(quickFilter)}>
            {quickFilter.label}
          </Label>
        ))}
      </div>
    </div>
  );
};

export default AuditFilter;
