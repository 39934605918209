import { GridState } from '@/grid/interface';
import { Settings, ignoreNextSettingsChangedEvent } from '../settings';
import { findParameterAsync } from './tableau';
import { FormulaType } from '@/components/formula/FormulaBuilder';
import { GridApi, GridOptions } from 'ag-grid-community';

export const getParameterValue = async (parameterName: string): Promise<GridState | null> => {
  if (!parameterName) return null;
  const parameter = await findParameterAsync(parameterName);
  if (parameter && typeof parameter !== 'boolean') {
    try {
      // The parameter value is a stringified JSON object but it can be empty or invalid
      return JSON.parse(parameter.currentValue.value || '{}');
    } catch (error) {
      return {};
    }
  }
  return null;
};

export const setParameterValue = async (parameterName: string, value: string) => {
  if (parameterName) {
    let parameter = await findParameterAsync(parameterName);
    if (parameter && typeof parameter !== 'boolean') {
      parameter.changeValueAsync(value ? JSON.stringify(value) : '');
    }
  }
};

const getFormulaColumns = async (stateParameter: string): Promise<Array<FormulaType>> => {
  const parameterValue = await getParameterValue(stateParameter);
  if (parameterValue) {
    return parameterValue.formulaColumns ?? [];
  }
  return [];
};

export async function getGridState(api: GridApi, stateParameter: string): Promise<GridState> {
  let columnState = api?.getColumnState();
  let columnGroupState = api?.getColumnGroupState();
  let pivotMode = api?.isPivotMode();
  let filterModel = api?.getFilterModel();
  let formulaColumns = (await getFormulaColumns(stateParameter)) || [];
  return {
    columnState,
    pivotMode,
    columnGroupState,
    filterModel,
    formulaColumns,
  };
}

export async function storeGridState(gridState?: GridState, stateParameter?: string, settings?: Settings) {
  // @ts-ignore
  let extension = window.tableau.extensions;
  let isAuthoring = extension.environment.mode === 'authoring';

  if (stateParameter) {
    let parameter = await findParameterAsync(stateParameter);
    if (!parameter)
      throw new Error(
        `Could not find parameter [${stateParameter}], did you rename or delete the parameter? You can reconfigure the [${stateParameter}] in the configuration -> appearance tab.`
      );

    // remove null, undefined, false values from columnState (parameter can be max 32k characters long)
    // note from ag-grid: For all state attributes, undefined means "do not apply this attribute" and null means "clear this attribute".

    // if aggFunc is removed, measures columns will be reset to default state(always enabled on reload) in pivot mode
    let keysToNotDelete = ['aggFunc', 'hide'];

    if (gridState && gridState.columnState) {
      gridState.columnState = gridState.columnState.map((element) => {
        Object.keys(element).forEach((key) => {
          if (key === 'hide' && settings?.hideNewColumns !== true) {
            element[key] = element[key] ?? false;
          }
          if (
            !element[key] &&
            typeof element[key] !== 'number' &&
            typeof element[key] !== 'string' &&
            !keysToNotDelete.includes(key)
          ) {
            delete element[key];
          }
        });
        return element;
      });
    }
    if (parameter && typeof parameter !== 'boolean') {
      parameter.changeValueAsync(gridState ? JSON.stringify(gridState) : '');
    }
  } else if (isAuthoring) {
    if (gridState) {
      extension.settings.set('gridState', JSON.stringify(gridState));
    } else {
      extension.settings.erase('gridState');
    }
    extension.settings.erase('columnState'); // cleanup legacy state
  }
  // check if its tableau authoring mode
  if (isAuthoring) {
    extension.settings.set('origin', 'gridState');
    ignoreNextSettingsChangedEvent();
    extension.settings.saveAsync();
  }
}

export function applyGridState(gridState: GridState, gridOptions: GridOptions, gridApi: GridApi, settings: Settings) {
  console.log('applying grid state...');
  gridOptions.pivotMode = gridState.pivotMode;
  if (gridState.columnState) {
    // NOTE: columns that don't exist in the current grid state
    // will be reset to a 'empty' grid state by this function
    // This will cause it to loose its aggregation function (among others)
    // TODO: We should make sure all columns are present in the column state
    gridApi.applyColumnState({
      state: gridState.columnState,
      applyOrder: true,
      defaultState: {
        hide: settings?.hideNewColumns ?? false,
      },
    });
  } else {
    gridApi.resetColumnState();
  }
  if (gridState.columnGroupState) {
    gridApi.setColumnGroupState(gridState.columnGroupState);
  } else {
    gridApi.resetColumnGroupState();
  }
  applyFilter(gridState, gridApi);
}
type NewFilterModel = {
  [key: string]: any;
};

async function applyFilter(gridState: GridState, api: GridApi) {
  const filterModel = gridState.filterModel;
  const newFilterModel: NewFilterModel = {};

  if (!filterModel) return api.setFilterModel(newFilterModel);

  for (const key of Object.keys(filterModel)) {
    const stateFilter = filterModel[key];
    const stateFilterType = getFilterType(stateFilter?.filterType);

    const filterInstance = await api?.getColumnFilterInstance(key);

    const filterType = Array.isArray(filterInstance?.filters) ? 'multi' : getFilterType(filterInstance?.filterNameKey);

    if (stateFilterType === filterType) {
      newFilterModel[key] = stateFilter;
    } else if (filterType === 'multi') {
      newFilterModel[key] = { filterType: 'multi', filterModels: [stateFilter] };
    } else if (stateFilterType === 'multi') {
      const matchingFilter = stateFilter.filterModels.find(
        (filter) => filter && getFilterType(filter?.filterType) === filterType
      );
      if (matchingFilter) newFilterModel[key] = matchingFilter;
    }
  }

  api.setFilterModel(newFilterModel);
}

function getFilterType(filterType: string) {
  const filterMapping: Record<string, string> = {
    number: 'number',
    numberFilter: 'number',
    date: 'date',
    dateFilter: 'date',
    set: 'set',
    setFilter: 'set',
    text: 'text',
    textFilter: 'text',
    multi: 'multi',
    multiFilter: 'multi',
  };
  return filterMapping[filterType] || 'default';
}
