import { gridApi } from '@/grid';
import { Audit, Comment, CommentWithReplies, PostComment } from '@/types/writeback';
import { UseMutationResult, useMutation, useQuery } from '@tanstack/react-query';
import { RangeSelectionChangedEvent } from 'ag-grid-community';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { getAllAudits, getAllComments, upsertComment } from './api';
import { queryClient } from './queryClient';
import { useWBEStore } from './store';

interface WBEContextType {
  comments: {
    data: CommentWithReplies[];
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
    addComment: UseMutationResult<Comment | Comment[], unknown, PostComment | PostComment[], unknown>;
  };
  audits: {
    data: Audit[];
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
  };
  rangeSelection: any[];
}

const WBEContext = createContext<WBEContextType | undefined>(undefined);

interface WBEProviderProps {
  children: ReactNode;
}

export const WBEProvider: React.FC<WBEProviderProps> = ({ children }) => {
  const url = useWBEStore((state) => state.url);
  const token = useWBEStore((state) => state.token);
  const schemaID = useWBEStore((state) => state.schemaID);
  const WBEComments = useWBEStore((state) => state.comments);
  const primaryKeyColumn = useWBEStore((state) => {
    return state.primaryKeyColumn && state.primaryKeyColumn !== ''
      ? state.primaryKeyColumn
      : state.comments.primaryKeyColumn;
  });
  const [selectedCellValues, setSelectedCellValues] = useState<
    {
      row: string;
      column: string;
    }[]
  >([]);

  const commentsQuery = useQuery({
    queryKey: ['comments'],
    queryFn: () => getAllComments(url, token, WBEComments.schemaID),
    enabled: !!token && !!WBEComments.schemaID && WBEComments.enabled,
    select: (response) => response.data,
  });

  const auditsQuery = useQuery({
    queryKey: ['audits'],
    queryFn: () => getAllAudits(url, token, schemaID),
    enabled: !!token && !!schemaID,
  });

  const addComment = useMutation({
    mutationFn: async (replyObject: PostComment | PostComment[]) => {
      if (Array.isArray(replyObject)) {
        const results = await Promise.all(
          replyObject.map((reply) => upsertComment(url, token, WBEComments.schemaID, reply))
        );
        return results;
      } else {
        return upsertComment(url, token, WBEComments.schemaID, replyObject);
      }
    },
    onSuccess: (result) => {
      queryClient.setQueryData(['comments'], (oldData: { data: Comment[] }) => {
        const newComments = Array.isArray(result) ? result : [result];
        return {
          ...oldData,
          data: [...oldData.data, ...newComments],
        };
      });
    },
  });

  useEffect(() => {
    gridApi?.addEventListener('rangeSelectionChanged', onRangeSelectionChanged);

    return () => {
      gridApi?.removeEventListener('rangeSelectionChanged', onRangeSelectionChanged);
    };
  }, [gridApi]);

  const onRangeSelectionChanged = (event: RangeSelectionChangedEvent) => {
    if (!event.finished) return;
    const cellSelected = getSelectedCellsInfo();
    const selectedCellValues = cellSelected.map((cellInfo) => {
      const { rowData, columnName } = cellInfo;
      return {
        column: columnName,
        row: rowData?.[primaryKeyColumn] ?? null,
      };
    });
    setSelectedCellValues(selectedCellValues);
  };

  function getSelectedCellsInfo() {
    const cellRanges = gridApi?.getCellRanges();

    if (!cellRanges || cellRanges.length === 0) {
      console.warn('No cells are selected');
      return [];
    }

    const selectedCellsInfo: any[] = [];

    cellRanges.forEach((range) => {
      const startRow = range.startRow;
      const endRow = range.endRow;
      const columns = range.columns;

      if (!startRow || !endRow || !columns) return;

      for (let rowIndex = startRow.rowIndex; rowIndex <= endRow.rowIndex; rowIndex++) {
        const rowNode = gridApi!.getDisplayedRowAtIndex(rowIndex);
        if (!rowNode) continue;

        const rowData = rowNode.data;

        columns.forEach((column) => {
          const columnName = column.getColId();

          selectedCellsInfo.push({
            columnName,
            rowData,
          });
        });
      }
    });

    return selectedCellsInfo;
  }

  const contextValue: WBEContextType = {
    comments: {
      data: commentsQuery.data || [],
      isLoading: commentsQuery.isLoading,
      isError: commentsQuery.isError,
      refetch: commentsQuery.refetch,
      addComment,
    },
    audits: {
      data: auditsQuery.data || [],
      isLoading: auditsQuery.isLoading,
      isError: auditsQuery.isError,
      refetch: auditsQuery.refetch,
    },
    rangeSelection: selectedCellValues,
  };

  return <WBEContext.Provider value={contextValue}>{children}</WBEContext.Provider>;
};

export const useWBEContext = (): WBEContextType => {
  const context = useContext(WBEContext);
  if (!context) {
    throw new Error('useWBEContext must be used within a WBEContextProvider');
  }
  return context;
};
