import { Label } from '@/components/ui/label'; // Ensure you have the Label component imported
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@utils/cn';
import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { useState } from 'react';
import { forwardRef } from 'react';
import { GoInfo } from 'react-icons/go';
import { ValidationRule } from '@/utils/validation';

const textareaVariants = cva(
  'flex w-full rounded-md border border-input border-gray-300 bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 font-karla  focus-visible:ring-0',
  {
    variants: {
      variant: {
        default: 'px-2 py-2',
        sm: 'px-2 py-4',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  validationRules?: ValidationRule[];
  onValidityChange?: (isValid: boolean) => void; // Add this prop
  height?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      variant,
      validationRules = [],
      onValidityChange,
      value: propValue,
      onChange,
      height = '100px',
      ...props
    },
    ref
  ) => {
    const [error, setError] = useState<string | null>(null);
    const [touched, setTouched] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    // Handle the internal value only if no `propValue` is provided
    const [internalValue, setInternalValue] = useState<string | number | null>(propValue || '');

    // If `propValue` is provided, we should always use it as the value
    const value = propValue !== undefined ? propValue : internalValue;

    const validate = (newVal = undefined) => {
      const valToValidate = newVal || value;
      if (validationRules.length > 0) {
        const firstFailedRule: ValidationRule | undefined = validationRules.find(
          (rule: ValidationRule) => !rule.validate(valToValidate)
        );
        if (firstFailedRule) {
          setError(firstFailedRule.errorMessage);
          onValidityChange && onValidityChange(false);
        } else {
          setError(null);
          onValidityChange && onValidityChange(true);
        }
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;

      // Update internal state only if the component is uncontrolled
      if (propValue === undefined) {
        setInternalValue(newValue);
      }

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      if (touched) {
        const timeout = setTimeout(() => {
          validate(newValue);
        }, 1000);

        setTypingTimeout(timeout);
      } else {
        const timeout = setTimeout(() => {
          validate(newValue);
          setTouched(true);
        }, 2000);

        setTypingTimeout(timeout);
      }

      if (onChange) {
        onChange(e); // Call parent's onChange handler
      }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;

      // Update internal state only if the component is uncontrolled
      if (propValue === undefined) {
        setInternalValue(newValue);
      }

      setTouched(true);
      // Perform validation on blur
      validate(newValue);
    };

    return (
      <div className="w-full">
        <textarea
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          className={cn(
            textareaVariants({ variant, className }),
            error ? 'border-red-500' : 'focus-visible:border-primary',
            'focus:outline-none focus:ring-2 focus:ring-red-500'
          )}
          ref={ref}
          value={value} // This ensures the component is controlled by the parent if `propValue` is provided
          style={{ minHeight: height }}
        />
        <p
          className="h-[7px] text-sm font-light text-red-500"
          role="alert">
          {error}
        </p>
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export interface InputWithLabelProps extends TextAreaProps {
  label: string;
  labelClassName?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  id: string;
  tooltip?: React.ReactNode;
}

const TextAreaWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  labelClassName,
  inputClassName,
  wrapperClassName,
  id,
  tooltip,
  disabled,
  variant,
  ...inputProps
}) => {
  return (
    <TooltipProvider>
      <div className={cn('flex flex-col gap-1', wrapperClassName)}>
        <div className="flex items-center space-x-1">
          <Label
            className={cn(
              disabled && 'cursor-not-allowed text-gray-700 opacity-70',
              {
                'text-lg': variant !== 'sm',
                'text-sm': variant === 'sm',
              },
              labelClassName
            )}
            htmlFor={id}>
            {label}
          </Label>
          {tooltip && (
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <div className="font-lg text-secondary">
                  <GoInfo
                    className="cursor-pointer"
                    size={13}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="top"
                align="center"
                className="m-4 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white">
                {typeof tooltip === 'string' ? <p>{tooltip}</p> : tooltip}
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        <TextArea
          variant={variant}
          name={id}
          className={cn('w-full', inputClassName)}
          id={id}
          disabled={disabled}
          {...inputProps}
        />
      </div>
    </TooltipProvider>
  );
};

const TextAreaWithSubLabel: React.FC<InputWithLabelProps> = ({
  label,
  labelClassName,
  inputClassName,
  wrapperClassName,
  id,
  disabled,
  ...inputProps
}) => {
  return (
    <div className={cn('flex flex-col gap-1', wrapperClassName)}>
      <TextArea
        name={id}
        className={cn('w-full', inputClassName)}
        id={id}
        disabled={disabled}
        {...inputProps}
      />
      <Label
        className={cn(
          'font-light text-gray-900',
          disabled && 'cursor-not-allowed text-gray-700 opacity-70',
          labelClassName
        )}
        htmlFor={id}>
        {label}
      </Label>
    </div>
  );
};

export { TextArea, TextAreaWithLabel, TextAreaWithSubLabel };
