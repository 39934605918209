import { formatDate as dateFnsFormatDate } from 'date-fns/format';

/**
 * A wrapper around date-fns formatDate that converts 'YYYY' to 'yyyy' for backward compatibility
 * @param {Date|Number} date - The date to format
 * @param {String} formatStr - The format string (supports both YYYY and yyyy)
 * @param {Object} options - Optional config for locales and other date-fns options
 * @returns {String} The formatted date string
 */
const formatDate = (date, formatStr, options?) => {
  // Replace 'YYYY' with 'yyyy' to maintain compatibility with the old formatting
  // And 'DD' with 'dd'
  if (formatStr && typeof formatStr === 'string') {
    const updatedFormatStr = formatStr.replace(/Y/g, 'y').replace(/D/g, 'd');
    return dateFnsFormatDate(date, updatedFormatStr, options);
  }
  return dateFnsFormatDate(date, formatStr, options);
};

export default formatDate;
