import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { gridApi } from '@/grid';
import type { Comment, PostComment } from '@/types/writeback';
import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { HiOutlineRefresh, HiSortAscending, HiSortDescending } from 'react-icons/hi';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { useWBEContext } from '../context';
import { useWBEStore } from '../store';
import CommentsList from './CommentsList';
import CommentFilter from './Filter';
import MenuButton from '@/components/ui/menuButton';
import { showToast } from '@/components/toast';
import { TextArea } from '@/components/ui/textArea';

export type DefaultCommentFilter = {
  column: string;
  row: string;
  status: 'resolved' | 'unresolved' | null;
  startDate: Date | null;
  endDate: Date | null;
  created_by: string;
};

export type CellComment = {
  column: string;
  row: string;
};

const Comment = () => {
  const { url, token, amIAllowedToDo } = useWBEStore();
  const WBEComments = useWBEStore((state) => state.comments);
  const primaryKeyColumn = WBEComments.primaryKeyColumn;
  const userInfo = useWBEStore((state) => state.userInfo);

  const { comments, rangeSelection } = useWBEContext();
  const [newComment, setNewComment] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  const [filter, setFilter] = useState<DefaultCommentFilter | null>(null);
  const [cellComment, setCellComment] = useState<CellComment | null>(null);
  const [usernames, setUsernames] = useState<string[]>([]);
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const [newCommentState, setNewCommentState] = useState(false);

  useEffect(() => {
    const users = comments.data.map((comment) => comment.created_by);
    setUsernames(Array.from(new Set(users)));
  }, [comments.data]);

  useEffect(() => {
    if (rangeSelection.length === 0) return setCellComment(null);
    if (rangeSelection.length !== 1) return setCellComment(null);
    const cell = rangeSelection[0];
    setCellComment({
      column: cell.column,
      row: cell.row,
    });
  }, [rangeSelection]);

  useEffect(() => {
    if (gridApi && comments.data.length > 0) {
      markCellsWithComments();
    }
  }, [gridApi, comments.data]);

  function markCellsWithComments() {
    if (!gridApi || !WBEComments.showIndicator) return;

    const commentMap: Record<string, Comment> = comments.data.reduce(
      (acc, comment) => {
        const key = `${comment.row_id}-${comment.column_id}`;
        acc[key] = comment;
        return acc;
      },
      {} as Record<string, Comment>
    );
    const getLeafColumns = (columns: ColDef[]): ColDef[] => {
      return columns.reduce<ColDef[]>((leafCols, column) => {
        if (column.children) {
          leafCols.push(...getLeafColumns(column.children as ColDef[]));
        } else {
          leafCols.push(column);
        }
        return leafCols;
      }, []);
    };

    const columns: ColDef[] = gridApi.getColumnDefs() as ColDef[];
    const leafColumns = getLeafColumns(columns);

    leafColumns.forEach((column) => {
      column.cellClassRules = {
        ...column.cellClassRules,
        'comment-cell': (params) => {
          const rowId = params.data?.[primaryKeyColumn];
          if (!rowId) return false;
          const key = `${rowId}-${column.field}`;
          return !!commentMap[key];
        },
      };
    });

    gridApi.setGridOption('columnDefs', columns);
    gridApi.redrawRows();
  }

  async function addNewComment() {
    setNewCommentState(false);
    setNewCommentLoading(true);
    if (rangeSelection.length === 0) {
      showToast('No cells are selected', 'error');
      setNewCommentLoading(false);

      return;
    }
    if (rangeSelection.length === 1) {
      const { row, column } = rangeSelection[0];

      const commentObject: PostComment = {
        row_id: row,
        column_id: column,
        comment: newComment,
        parent_id: null,
        resolved: false,
        created_by: userInfo?.display_name ?? 'Anonymous',
      };
      comments.addComment.mutate(commentObject);
    } else {
      const commentObjects: PostComment[] = rangeSelection.map(({ row, column }) => ({
        row_id: row,
        column_id: column,
        comment: newComment,
        parent_id: null,
        resolved: false,
        created_by: userInfo?.display_name ?? 'Anonymous',
      }));
      comments.addComment.mutate(commentObjects);
    }
    setNewComment('');
    setNewCommentLoading(false);
  }

  function clearSelection() {
    gridApi?.deselectAll();
    gridApi?.clearRangeSelection();
  }

  if (!WBEComments.enabled) {
    return (
      <>
        <div className="m-4 mt-4">
          <h1 className="text-xl">Collaborate with Comments</h1>
          <br />
          Use cell-level comments to collaborate with other users. Share insights, leave feedback, or ask questions
          right within the data, making it easy to work together without needing to switch to other tools.
        </div>
        <div className="m-4 mt-4 text-lg font-bold text-red-500">Commenting on cells is currently not enabled.</div>
      </>
    );
  }

  if (!url || !token || !WBEComments.schemaID || !WBEComments.primaryKeyColumn) {
    return <div className="mt-4 text-center text-lg font-bold text-red-500">Comments are not configured</div>;
  }

  if (comments.isLoading) {
    return <div className="mt-4 text-center text-lg">Loading Comments...</div>;
  }

  if (comments.isError) {
    return <div className="mt-4 text-center text-lg font-bold text-red-500">Failed to fetch comments</div>;
  }

  return (
    <div className="flex h-full flex-col overflow-hidden pt-2">
      {/* <Button
        variant="ghost"
        onClick={markCellsWithComments}>
        Highlight Cells with Comments
      </Button> */}
      <div className="border-b border-gray-300 px-2 pb-3">
        <div className="flex items-center justify-between gap-2">
          <div className="mt-[2px] flex-1">
            <Input
              className="flex-1 py-0"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="mt-[-5px] flex items-center gap-2">
            <CommentFilter
              filter={filter}
              setFilter={setFilter}
              currentUser={userInfo?.username || ''}
              usernames={usernames}
            />

            <MenuButton
              icon={sort === 'desc' ? <HiSortDescending size={20} /> : <HiSortAscending size={20} />}
              onClick={() => setSort(sort === 'asc' ? 'desc' : 'asc')}
              title="Sort the comments by the date they were created"
            />
            <MenuButton
              icon={<HiOutlineRefresh size={20} />}
              onClick={() => comments.refetch()}
              shouldRotate={true}
              title="Refresh commnents"
            />
          </div>
        </div>
        {cellComment && (
          <Button
            variant="link"
            onClick={clearSelection}
            className="w-fit p-0">
            <MdOutlineArrowBackIos
              className=""
              size={15}
            />
            <div>Back to all comments</div>
          </Button>
        )}
      </div>
      <div className="flex-1 overflow-auto px-2 pt-4">
        <CommentsList
          filter={filter}
          cellComment={cellComment}
          setCellComment={setCellComment}
          search={search}
          sort={sort}
        />
      </div>

      {rangeSelection.length !== 0 && (
        <div className="flex flex-col gap-1 border-t border-gray-300 px-2">
          {amIAllowedToDo('write', 'comment') &&
            (newCommentState ? (
              <>
                <div className="flex items-center justify-between">
                  <Label>
                    You have selected <strong> {rangeSelection.length}</strong> cell
                    {rangeSelection.length > 1 ? 's' : ''}
                  </Label>
                </div>
                <div className="flex flex-col">
                  <TextArea
                    id="new-comment"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Type your comment here"
                    height="50px"
                    className="w-full"
                  />
                  <div className="flex justify-between">
                    <Button
                      variant="outline"
                      onClick={() => setNewCommentState(false)}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={!newComment || newCommentLoading}
                      isLoading={newCommentLoading}
                      onClick={addNewComment}>
                      Save Comment
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="mx-2 mt-2 flex flex-row items-center justify-between">
                <Label className="self-center">
                  You have selected <strong> {rangeSelection.length}</strong> cell
                  {rangeSelection.length > 1 ? 's' : ''}
                </Label>

                <Button
                  variant="outline"
                  onClick={() => setNewCommentState(true)}
                  className="self-end">
                  Write comment
                </Button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Comment;
