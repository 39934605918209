import { CheckboxWithLabel } from '@/components/ui/checkbox';
import { Input, InputWithLabel } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { FormulaType } from '@/types/settings';
import { cn } from '@/utils/cn';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { PiTextTBold } from 'react-icons/pi';

const FormattingOptions = ({
  formula,
  currencySupported,
  setFormula,
  setErrors,
}: {
  formula: FormulaType;
  currencySupported: boolean;
  setFormula: (f: FormulaType) => void;
  setErrors: (e: any) => void;
}) => {
  const [formattingOpen, setFormattingOpen] = useState(false);

  function changeFormat(key: keyof FormulaType['format'], value: string | number) {
    if (key === 'style' && value !== 'currency') {
      setErrors((e) => ({ ...e, currency: '' }));
    }
    setFormula((f: FormulaType) => ({ ...f, format: { ...f.format, [key]: value } }));
  }

  const {
    style,
    maximumFractionDigits,
    currency,
    prefix,
    suffix,
    negativeValueFormat,
    positiveValueFormat,
    nullReplaceString,
    kSeparator,
    mSeparator,
    bSeparator,
    tSeparator,
    useGrouping,
  } = formula.format;

  return (
    <div className="rounded border">
      <div
        className="flex items-center justify-between gap-2 p-2"
        onClick={() => setFormattingOpen((o) => !o)}>
        <div>
          <PiTextTBold size={16} />
        </div>
        <h3 className="flex-1 text-left text-lg font-bold">Formatting Options</h3>
        <div className={cn('box-border grid place-items-center p-1', formattingOpen ? 'rotate-180' : 'rotate-0')}>
          <FaChevronDown size={13} />
        </div>
      </div>
      <div className={cn('flex-col justify-between gap-1.5 p-2', formattingOpen ? 'flex' : 'hidden')}>
        <div className="flex items-start justify-between gap-1">
          <div className="flex flex-1 flex-col items-start">
            <Label>Format</Label>
            <Select
              onValueChange={(value) => changeFormat('style', value as string)}
              value={style}>
              <SelectTrigger>
                <SelectValue placeholder="Select format type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="decimal">Decimal</SelectItem>
                <SelectItem value="currency">Currency</SelectItem>
                <SelectItem value="percent">Percentage</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <InputWithLabel
            id="decimals"
            label="Decimals"
            value={maximumFractionDigits ? parseInt(maximumFractionDigits) : ''}
            onChange={(e) => changeFormat('maximumFractionDigits', e.target.value)}
            type="number"
            wrapperClassName="gap-0 flex-1"
          />
        </div>

        {style === 'currency' && (
          <div>
            <Label>Currency</Label>
            <Input
              value={currency}
              onChange={(e: { target: { value: string } }) => {
                changeFormat('currency', e.target.value);
                if (e.target.value) setErrors((e) => ({ ...e, currency: '' }));
              }}
              type="text"
            />
            {currency && (
              <div className={cn('mt-2 max-w-[300px]', currencySupported ? 'text-green-900' : 'text-red-900')}>
                This currency ({currency}) is {currencySupported ? 'supported' : 'not supported'}.{' '}
                {currencySupported ? '' : 'Please use a valid currency Eg: USD, EUR.'}
              </div>
            )}
          </div>
        )}

        <div className="flex items-start justify-between gap-1">
          <div className="flex flex-1 flex-col gap-0">
            <Label className="text-lg">Positive Values</Label>
            <Select
              value={positiveValueFormat || 'plus'}
              onValueChange={(value) => changeFormat('positiveValueFormat', value)}>
              <SelectTrigger>
                <SelectValue placeholder="Select format type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="plus">
                  {prefix}1234{suffix}
                </SelectItem>
                <SelectItem value="includeplus">
                  +{prefix}1234{suffix}
                </SelectItem>
                <SelectItem value="parentheses">
                  ({prefix}1234{suffix})
                </SelectItem>
                <SelectItem value="plusparentheses">
                  ({prefix}+1234{suffix})
                </SelectItem>
                <SelectItem value="plusright">
                  {prefix}1234{suffix}+
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-1 flex-col gap-0">
            <Label className="text-lg">Negative Values</Label>
            <Select
              value={negativeValueFormat || 'minus'}
              onValueChange={(value) => changeFormat('negativeValueFormat', value)}>
              <SelectTrigger>
                <SelectValue placeholder="Select format type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="minus">
                  -{prefix}1234{suffix}
                </SelectItem>
                <SelectItem value="parentheses">
                  ({prefix}1234{suffix})
                </SelectItem>
                <SelectItem value="minusparentheses">
                  ({prefix}-1234{suffix})
                </SelectItem>
                <SelectItem value="minusright">
                  {prefix}1234{suffix}-
                </SelectItem>
                <SelectItem value="none">
                  {prefix}1234{suffix}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <InputWithLabel
          id="nullReplaceString"
          label="Replace null values"
          placeholder="Replace null values"
          value={nullReplaceString}
          onChange={(e: { target: { value: string } }) => changeFormat('nullReplaceString', e.target.value)}
          type="text"
          wrapperClassName="gap-0"
        />

        <InputWithLabel
          id="prefix"
          label="Prefix"
          value={prefix}
          onChange={(e: { target: { value: string } }) => changeFormat('prefix', e.target.value)}
          type="text"
          wrapperClassName="gap-0"
        />

        <InputWithLabel
          id="suffix"
          label="Suffix"
          value={suffix}
          onChange={(e: { target: { value: string } }) => changeFormat('suffix', e.target.value)}
          type="text"
          wrapperClassName="gap-0"
        />

        <InputWithLabel
          wrapperClassName="gap-0"
          id="thousandsSeparator"
          label="Thousands (K)"
          value={kSeparator || ''}
          placeholder="Thousands Display Unit"
          onChange={(e) => changeFormat('kSeparator', e.target.value)}
        />

        <InputWithLabel
          wrapperClassName="gap-0"
          id="millionsSeparator"
          label="Millions (M)"
          value={mSeparator || ''}
          placeholder="Millions Display Unit"
          onChange={(e) => changeFormat('mSeparator', e.target.value)}
        />

        <InputWithLabel
          wrapperClassName="gap-0"
          id="billionsSeparator"
          label="Billions (B)"
          value={bSeparator || ''}
          placeholder="Billions Display Unit"
          onChange={(e) => changeFormat('bSeparator', e.target.value)}
        />

        <InputWithLabel
          wrapperClassName="gap-0 mb-2"
          id="trillionsSeparator"
          label="Trillions (T)"
          value={tSeparator || ''}
          placeholder="Trillions Display Unit"
          onChange={(e) => changeFormat('tSeparator', e.target.value)}
        />

        <CheckboxWithLabel
          name="includeThousandsSeparator"
          id="includeThousandsSeparator"
          label="Include thousands separator"
          checked={useGrouping}
          onChange={(e) => changeFormat('useGrouping', e.target.checked)}
        />
      </div>
    </div>
  );
};

export default FormattingOptions;
