import { CommentWithReplies } from '@/types/writeback';
import autoAnimate from '@formkit/auto-animate';
import { useEffect, useMemo, useRef } from 'react';
import { CellComment, DefaultCommentFilter } from '.';
import { useWBEContext } from '../context';
import CommentCard from './CommentCard';

type CommentsListProps = {
  filter: DefaultCommentFilter | null;
  cellComment: CellComment | null;
  setCellComment: (comment: CellComment | null) => void;
  search: string;
  sort: 'asc' | 'desc';
};

const CommentsList = ({ filter, cellComment, setCellComment, search, sort }: CommentsListProps) => {
  const { comments, rangeSelection } = useWBEContext();

  const parent = useRef(null);
  useEffect(() => {
    if (parent.current) autoAnimate(parent.current);
  }, []);

  const filteredComments = useMemo(() => {
    const finalFilter = { ...(filter || {}), ...(cellComment || {}) };

    return comments.data.filter((comment) => {
      const matchesSearch =
        !search ||
        comment.comment.toLowerCase().includes(search.toLowerCase()) ||
        comment.created_by.toLowerCase().includes(search.toLowerCase()) ||
        comment.children?.some(
          (child) =>
            child.comment.toLowerCase().includes(search.toLowerCase()) ||
            child.created_by.toLowerCase().includes(search.toLowerCase())
        );

      const matchesColumn = !finalFilter.column || comment.column_id === finalFilter.column;
      const matchesRow = !finalFilter.row || comment.row_id === finalFilter.row;
      const matchesStatus = !finalFilter.status || comment.resolved === (finalFilter.status === 'resolved');
      const matchesCreatedAt =
        (!finalFilter.startDate || new Date(comment.created_at) >= finalFilter.startDate) &&
        (!finalFilter.endDate || new Date(comment.created_at) <= finalFilter.endDate);
      const matchesCreatedBy =
        !finalFilter.created_by || comment.created_by.toLowerCase().includes(finalFilter.created_by.toLowerCase());

      const matchesFilter = matchesColumn && matchesRow && matchesStatus && matchesCreatedAt && matchesCreatedBy;

      return matchesSearch && matchesFilter;
    });
  }, [comments, search, filter, cellComment]);

  const orderedComments: CommentWithReplies[] = useMemo(() => {
    let parentComments = filteredComments.filter((comment) => !comment.parent_id);

    const getTimestamp = (comment) => new Date(comment.created_at).getTime();
    parentComments.sort((a, b) => {
      return sort === 'asc' ? getTimestamp(a) - getTimestamp(b) : getTimestamp(b) - getTimestamp(a);
    });

    return parentComments;
  }, [filteredComments, sort]);

  return orderedComments.length === 0 ? (
    <div>
      <div className="mt-4 text-center text-lg">
        No Comments have been made {rangeSelection.length !== 0 && <>for this cell</>}
      </div>
      <div className="mt-4 text-center text-lg">
        {rangeSelection.length === 0 ? (
          <>Select a cell and click Write comment below to start.</>
        ) : (
          <>Click Write comment below to start.</>
        )}
      </div>
    </div>
  ) : (
    <div ref={parent}>
      {orderedComments.map((comment) => {
        return (
          <div key={comment.id}>
            <CommentCard
              comment={comment}
              refreshComments={() => comments.refetch()}
              setCellComment={setCellComment}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CommentsList;
