import { Settings, getSettings } from '@/settings';
import { ColumnConfig, ContinuousColorConfig } from '@/types/settings';
import { EventBus } from '@/utils/event-bus';
import { debounce } from '@/utils/functional';
import { formatWithValueFormatter } from '@/utils/grid';
import { forEachSelectedNodes } from '@/utils/grid-selection';
import { getGridState } from '@/utils/grid-state';
import { findParameterAsync, getParametersAsync } from '@/utils/tableau';
import { useWBEStore } from '@/writeback/store';
import {
  AgGridEvent,
  BodyScrollEvent,
  ColDef,
  ColGroupDef,
  ColumnPivotChangedEvent,
  ColumnPivotModeChangedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  ColumnVisibleEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  IAggFuncParams,
  IRowNode,
  RangeSelectionChangedEvent,
  RowDataUpdatedEvent,
  RowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { gridApi } from '..';
import { compileCalculation } from '../calculation';
import {
  BarchartCellRenderer,
  EmojiCellRenderer,
  HTMLCellRenderer,
  ImageCellRenderer,
  MarkdownCellRenderer,
} from '../cellRenderers';
import { reloadCellRenderers } from '../mapping';
import { getContextMenuItems, getMainMenuItems } from '../menu';

const { primaryKeyColumn, bulkValueSetter, cellEditingStarted } = useWBEStore.getState();

let triggerHeaderHeight = debounce(calculateHeaderHeight, 200);

export const gridOptions: GridOptions = {
  enableRangeSelection: true,
  groupHeaderHeight: 28,
  rowSelection: 'multiple',
  groupSelectsFiltered: true,
  columnMenu: 'legacy',
  aggFuncs: {
    None: () => undefined,
    Calc: calcAggFunc,
    CountD: countDAggFunc,
    SelectiveAgg: selectiveAggregationAggFunc,
    List: listOfItemsFunc,
  },
  components: {
    htmlCellRenderer: HTMLCellRenderer,
    mdCellRenderer: MarkdownCellRenderer,
    emojiCellRenderer: EmojiCellRenderer,
    imageCellRenderer: ImageCellRenderer,
    barChartCellRenderer: BarchartCellRenderer,
  },
  processCellForClipboard: formatWithValueFormatter,
  onCellEditingStarted: cellEditingStarted,
  onCellEditingStopped: bulkValueSetter,
  onGridReady(params) {
    if (!params.api) return; // make ts happy

    let selectionChangedHandler = debounce(triggerSelectionChangedEvent, 250);
    params.api.addEventListener('rangeSelectionChanged', selectionChangedHandler);
    params.api.addEventListener('selectionChanged', selectionChangedHandler);

    params.api.addEventListener('rowClicked', (params) => {
      if (params?.node?.allChildrenCount && params.node.allChildrenCount > 0) {
        var select = true;
        var clearSelection = true;
        if (params?.event?.ctrlKey) {
          select = !params.node.isSelected();
          clearSelection = false;
        }
        params.node.setSelected(select, clearSelection);
      }
    });
    params.api.addEventListener('rowDoubleClicked', (params) => {
      params.node.setSelected(true, false);
    });
    const getTextFromCell = (cell: ColGroupDef) => {
      const children = Array.from(cell.children);
      const textNode = children.find((node: ColDef) => {
        if (typeof node.cellClass === 'string' || Array.isArray(node.cellClass)) {
          return node.cellClass.includes('ag-column-drop-vertical-cell-text');
        }
      });

      if (textNode && 'innerText' in textNode) {
        const text: string = textNode.innerText as string;
        const slicedText = text.match(/\((.)*\)/g)?.[0];
        return slicedText?.substr(1, slicedText.length - 2);
      }
      return '';
    };

    params.api.addEventListener('dragStopped', (params) => {
      const movedCell = params.target.parentElement;
      // todo: this is a hack, find a better way to check if the moved cell is in the values section
      if (movedCell && movedCell.ariaLabel?.endsWith('aggregation type')) {
        const colDefs = params.api.getColumns();
        const namedColdefs = colDefs?.map((column) => {
          let headerName = '';
          let headerValueGetter = column.getUserProvidedColDef()?.headerValueGetter;
          if (headerValueGetter && typeof headerValueGetter === 'function')
            headerName = headerValueGetter({
              column: { api: params.api, aggFunc: column.getAggFunc() }, //Are we using columnApi anywhere ?
            });
          return { column, headerName };
        });
        // Get all the cells in the values section and get the text from them
        const cells = Array.from(document.getElementsByClassName('ag-column-drop-vertical-cell'));
        // @ts-ignore
        const valueCells = cells.filter((cell) => cell.parentNode?.ariaLabel === 'Values');
        const names = valueCells.map(getTextFromCell);
        const orderedColumnDefs = names.map(
          (colName) => namedColdefs?.find((column) => column.headerName === colName)?.column
        );
        // only keep the last part of the name between the parentheses including the parentheses
        const movedCellName = getTextFromCell(movedCell);
        const movedColumnDef = namedColdefs?.find((column) => column.headerName === movedCellName)?.column;
        // remove the moved cell from the values to trigger a column change event and reload the columns
        params.api.removeValueColumns([movedColumnDef]);
        // add the moved cell again in the new order
        params.api.setValueColumns(orderedColumnDefs);
      }
    });

    let settings = getSettings() as Settings;
    if (settings.enableAutoAggregation) {
      const context = params.context;
      if (context?.updateParameterValues) {
        // the layout of the table has changed, we need to update the parameter values.
        // the layout changed using the mult-layout switcher feature.
        context.updateParameterValues = false;
        updateParameterValuesForAutoAggregation(params);
      }
      let columnChangedHandler = debounce(triggerColumnChangedHandler, 200);

      params.api.addEventListener('columnVisible', (event: ColumnVisibleEvent) => {
        if (event.column?.isRowGroupActive()) {
          columnChangedHandler(event.column?.getColId(), true);
        } else if (!event.api.isPivotMode()) {
          if (event.columns && event.columns?.length > 1) {
            for (let i = 0; i < event.columns.length; i++) {
              triggerColumnChangedHandler(event.columns[i].getColId(), event.columns[i].isVisible());
            }
          } else {
            columnChangedHandler(event.column?.getColId(), event.column?.isVisible());
          }
        }
      });
      params.api.addEventListener('columnValueChanged', (event: ColumnValueChangedEvent) => {
        columnChangedHandler(event.column?.getColId(), event.column?.isValueActive());
      });
      params.api.addEventListener('columnPivotChanged', (event: ColumnPivotChangedEvent) => {
        columnChangedHandler(event.column?.getColId(), event.column?.isPivotActive());
      });
      params.api.addEventListener('columnRowGroupChanged', (event: ColumnRowGroupChangedEvent) => {
        let { column, columns } = event;
        if (!column) {
          column = columns?.pop() ?? null;
        }
        if (!column) return;

        if (params.api.isPivotMode()) {
          columnChangedHandler(column.getColId(), column.isRowGroupActive());
        } else {
          columnChangedHandler(column.getColId(), column.isRowGroupActive() || column.isVisible());
        }
      });

      params.api.addEventListener('columnPivotModeChanged', updateParameterValuesForAutoAggregation);
    }
    // for some reason displayedColumnsChanged doesn't capture resizes
    // columnResized triggers multiple times so we debounce the handler
    // here instead of where we register the actual handler...
    let stateChangedHandler = debounce(triggerStateChangedEvent, 1000);
    params.api.addEventListener('displayedColumnsChanged', stateChangedHandler);
    params.api.addEventListener('columnResized', stateChangedHandler);
    params.api.addEventListener('sortChanged', stateChangedHandler);
    params.api.addEventListener('filterChanged', stateChangedHandler);
    params.api.addEventListener('modelUpdated', stateChangedHandler);

    EventBus.triggerEvent('startFetching', params);

    setTimeout(async function() {
      let state = await getGridState(params.api, settings.stateParameter ?? '');
      if (Array.isArray(state.columnState)) {
        let isDefaultColumnState = state.columnState.every((col) => {
          if (col.colId === 'index') {
            return col.width === 100;
          }
          return col.width === 200;
        });

        if (isDefaultColumnState) {
          params.api.autoSizeAllColumns();
        }
      }
    }, 250);

    // change the color of the WBE Button
    if (settings.showWbePanel) {
      const buttons = document.querySelectorAll('.ag-side-button-label');
      buttons.forEach((button) => {
        if (button.textContent.includes('InputTables')) {
          button.parentNode.style.backgroundColor = '#3B474F';
          button.parentNode.style.color = '#fff';
        }
      });
    }
  },
  onColumnRowGroupChanged: triggerHeaderHeight,
  onColumnResized: triggerHeaderHeight,
  onFirstDataRendered: (params) => {
    let settings = getSettings() as Settings;

    // Redraw rows to update row height based on cell content
    if (settings.autoRowHeight) params.api.redrawRows();

    triggerHeaderHeight(params);
  },
  onModelUpdated: (params) => {
    // keepUndoRedoStack: true if all we did is changed row height, data still the same, no need to clear the undo/redo stacks
    if (params.keepUndoRedoStack) return;

    let settings = getSettings() as Settings;
  },
  onFilterChanged: (params) => {
    let settings = getSettings() as Settings;
  },
  onViewportChanged: (params) => {
    const settings = getSettings() as Settings;

    updateCellrenderersWithCurrentColAggData(settings);
  },
  onBodyScroll: triggerHeaderHeight,
  onRowDataUpdated: triggerHeaderHeight,
  // onRowDataUpdated: triggerHeaderHeight,
  getContextMenuItems: getContextMenuItems,
  getMainMenuItems: getMainMenuItems,
};
if (primaryKeyColumn) {
  gridOptions.getRowId = (params: GetRowIdParams) => {
    return String(params.data[primaryKeyColumn] ?? params.data.index);
  };
}

function calculateHeaderHeight(
  params:
    | ColumnResizedEvent
    | BodyScrollEvent
    | ColumnRowGroupChangedEvent
    | FirstDataRenderedEvent
    | RowDataUpdatedEvent
) {
  let tallestHeader = 0;

  let headers = Array.from(document.querySelectorAll('.ag-header-cell-text'));

  headers.forEach((el) => {
    tallestHeader = tallestHeader > el.clientHeight ? tallestHeader : el.clientHeight;
  });

  const headerPadding = 20;
  params.api.setGridOption('headerHeight', tallestHeader + headerPadding);
}

function triggerSelectionChangedEvent(event: RangeSelectionChangedEvent | SelectionChangedEvent) {
  if (!event) return; // make ts happy

  let selectedRows = new Set();
  forEachSelectedNodes(event.api, (rowNode: Set<RowNode>) => {
    selectedRows.add(rowNode);
  });

  EventBus.triggerEvent('selectionChanged', Array.from(selectedRows));
}

function triggerStateChangedEvent() {
  EventBus.triggerEvent('stateChanged');
}

async function triggerColumnChangedHandler(colId?: string, newValue?: any) {
  const pm = await findParameterAsync(colId);
  if (pm && typeof pm !== 'boolean') {
    if (pm.currentValue === newValue) return;

    let value = JSON.stringify(newValue);

    if (pm?.allowableValues?.type === 'list') {
      value = pm.allowableValues.allowableValues?.find((x) => x.nativeValue === newValue)?.formattedValue ?? '';
    }
    await pm.changeValueAsync(value);
  }
}

async function updateParameterValuesForAutoAggregation(params: ColumnPivotModeChangedEvent | GridReadyEvent) {
  const parameters = await getParametersAsync();
  const parameterNames = parameters?.map((parameter) => parameter.name);
  const columnColIds = params.api.getColumns()?.map((column) => column.getColId());
  const parametersContainingColumn = parameterNames?.filter((parameter) => columnColIds?.includes(parameter));

  if (!parametersContainingColumn?.length) return;

  for (const colId of parametersContainingColumn) {
    const column = params.api.getColumn(colId);
    const columnVisibility =
      column?.isRowGroupActive() ||
      (!params.api.isPivotMode() ? column?.isVisible() : column?.isPivotActive() || column?.isValueActive());
    await triggerColumnChangedHandler(colId, columnVisibility);
  }
}

interface AggregationData {
  min: number;
  max: number;
}

const updateCellrenderersWithCurrentColAggData = debounce(function(settings: Settings) {
  const { columnConfig } = settings;
  if (!columnConfig) return;

  let columnsWithAutoBarChart = false;

  Object.keys(columnConfig).forEach((columnKey) => {
    const config = columnConfig[columnKey] as ColumnConfig;
    if (!config) return false;
    if (config?.color && 'type' in config.color && config.color.type === 'continuous' && config.color.barChart) {
      const colorConfig = config.color as ContinuousColorConfig;
      if (!columnsWithAutoBarChart) columnsWithAutoBarChart = !!(colorConfig.autoMax || colorConfig.autoMin);
    }
  });

  if (!columnsWithAutoBarChart) return;

  const colAggData: { [key: string]: AggregationData } = {}; //This should be a number

  const forEachNodeFunc = (node: IRowNode) => {
    // Potential feature: Make this configurable
    if (!node.displayed) return;

    const row = node.aggData ?? node.data;

    for (const key in row) {
      const colorConfig = columnConfig[key]?.color;
      let value = gridApi?.getCellValue({ rowNode: node, colKey: key, useFormatter: false });

      // If the value is an object, this is from a calculated column and we need to get the value from it
      if (typeof value === 'object' && 'value' in value) value = value.value;

      if (typeof value === 'number') {
        // @ts-expect-error - Only do this if the setting is enabled
        if (colorConfig?.barchartUseAbsoluteValues) value = Math.abs(value);

        // Get the min and max values per column
        if (colAggData.hasOwnProperty(key)) {
          colAggData[key].max = Math.max(colAggData[key].max, value);
          colAggData[key].min = Math.min(colAggData[key].min, value);
        } else {
          colAggData[key] = { min: value, max: value };
        }
      }
    }
  };

  // Potential feature: Make this configurable to maybe include grand total row
  // this can be done with gridApi?.getRenderedNodes()
  gridApi?.forEachNode(forEachNodeFunc); // <- this one inlcudes all hidden nodes but no total row nodes

  gridOptions.context = {
    ...gridOptions.context,
    colAggData,
  };

  reloadCellRenderers(gridOptions);
}, 10);

function calcAggFunc(params: IAggFuncParams) {
  const { context, values } = params;
  // HACK: for grouping, the aggData is either undefined or not up to date in most cases
  // so we rely on the valueGetter which always contains upto date aggData
  // pivot calculation depend on values
  if (values.length === 0) return undefined;

  const tableauColumns = context.tableauColumns;
  const column = tableauColumns.find((column: { index: string }) => column?.index === params.column.getColId());
  let calc = compileCalculation(column, tableauColumns);
  if (!calc) return undefined;
  // TODO: calculate only fields that are used in the calculation
  // const usedFields = getUsedFields(column.calculation);

  let mergeredValues: { [key: string]: number } = {};
  for (let i = 0; i < values.length; i++) {
    const valueObj = values[i];
    if (!valueObj) continue;

    for (const [key, value] of Object.entries(valueObj)) {
      // if value is a string or an object then continue
      // objects are calculation columns, string don't need to be considered in the calculation
      if (typeof value === 'number') {
        if (key in mergeredValues) {
          // only summing is supported for now
          // TODO: support other aggregation functions
          mergeredValues[key] = mergeredValues[key] + value;
        } else {
          mergeredValues[key] = value;
        }
      }
    }
  }
  const calculationResult = calc(mergeredValues);
  mergeredValues.value = calculationResult;
  mergeredValues.valueOf = () => calculationResult;
  mergeredValues.toString = () => (calculationResult ? `${calculationResult}` : '');
  return mergeredValues;
}

function countDAggFunc(params: IAggFuncParams): any {
  const underlyingValues = getUnderlyingValues(params.values);
  const uniqueValues = new Set(underlyingValues);
  return {
    value: uniqueValues.size,
    toString: () => uniqueValues.size.toString(),
    valueOf: () => uniqueValues.size,
    underlyingValues: Array.from(uniqueValues.values()),
  };
}

// flat the array with infinite depth and remove undefined and null values from the array and return the underlying values
const getUnderlyingValues = (values: any[]) => values.map((value) => getUnderlyingValue(value)).flat(Infinity);

// To get the underlying values from the cell value
// if the cell value is an array then it will return the underlying values of the array
// if the cell value is an object then it will return the underlying values of the object
const getUnderlyingValue = (value: any): any[] => {
  if (value === undefined || value === null) {
    return [];
  }

  if (Array.isArray(value)) {
    return getUnderlyingValues(value);
  }

  if (value.hasOwnProperty('underlyingValues')) {
    return value.underlyingValues;
  }
  return value;
};

function listOfItemsFunc(params: IAggFuncParams) {
  // always a grouped row from here on
  const currentAggColumn = params.column.getColId();

  let set = new Set();
  function getChildrenNodeFinalValue(children: IRowNode[] | null) {
    children?.forEach((child, index) => {
      set.add(child.data[currentAggColumn]);
    });
    return Array.from(set).sort().join(', ');
  }
  const children = params.rowNode.allLeafChildren;
  return getChildrenNodeFinalValue(children);
}

function selectiveAggregationAggFunc(params: IAggFuncParams) {
  if (params.values.length === 1) return params.values[0];

  // always a grouped row from here on
  const columnMappingToFlag = params.context.selectiveAggregationMap;
  const currentAggColumn = params.column.getColId();
  const currentGroupColumnField = params.rowNode.field;
  const currentGroupColumnFlag =
    currentGroupColumnField && columnMappingToFlag ? columnMappingToFlag[currentGroupColumnField] : '';

  function getLeafChildValue(node: IRowNode) {
    const flagIndicator = currentGroupColumnFlag ? (node.data?.[currentGroupColumnFlag] ?? '1') : '1';
    return flagIndicator === '1' ? node.data[currentAggColumn] : 0;
  }

  function getChildrenNodeFinalValue(children: IRowNode[] | null) {
    let finalValue = 0;
    children?.forEach((child, index) => {
      if (typeof getLeafChildValue(child) === 'number') {
        finalValue += getLeafChildValue(child);
      }
    });

    return finalValue;
  }

  // MARK: This is a special case.
  // it only applies when a group row has 2 children, where
  // 1. one child is a leaf node (no children)
  // 2. the other child is a group node (has children) and all the children have a flag value of 0
  // in this case, we should return the value in the leaf node
  const rowNode = params.rowNode;
  if (
    rowNode.childrenAfterFilter?.length === 2 &&
    !rowNode.childrenAfterFilter[0].group &&
    rowNode.childrenAfterFilter[1].group
  ) {
    // there are 2 child nodes, one is a leaf and the other is a group
    // if the children of the group have all 0's in their flag value, then we should return the value in the leaf node
    const leafNode = rowNode.childrenAfterFilter[0];
    const groupNode = rowNode.childrenAfterFilter[1];
    const groupNodeLeafValue = getChildrenNodeFinalValue(groupNode.allLeafChildren);
    if (groupNodeLeafValue === 0) {
      return leafNode.data[currentAggColumn];
    }
  }

  const children = params.rowNode.allLeafChildren;
  return getChildrenNodeFinalValue(children);
}
