import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

// Custom Select Cell Editor
export default class CustomSelectCellEditor implements ICellEditorComp {
  private eGui!: HTMLElement;
  private eSelect!: HTMLSelectElement;
  private valueMap!: { [key: string]: string }; // Object to hold key-value pairs
  private selectedKey!: string;

  init(params: ICellEditorParams & { options: { [key: string]: string } }): void {
    // Store the key-value object from params
    this.valueMap = params.options;

    this.eGui = document.createElement('div');
    this.eGui.style.height = '100%';
    this.eGui.style.width = '100%';
    this.eGui.style.display = 'flex';

    this.eSelect = document.createElement('select');
    this.eSelect.style.width = '100%';
    this.eSelect.style.height = '100%';
    this.eSelect.style.boxSizing = 'border-box';

    // Populate select options
    for (const key in this.valueMap) {
      const option = document.createElement('option');
      option.value = key; // The key is the value stored in the grid's data
      option.textContent = this.valueMap[key]; // The value is what's displayed to the user
      this.eSelect.appendChild(option);
    }

    // Set the initial selected value based on the passed value (key)
    this.eSelect.value = params.value || '';

    this.eGui.appendChild(this.eSelect);

    // Listen for changes to update the selected key
    this.eSelect.addEventListener('change', () => {
      this.selectedKey = this.eSelect.value;
    });
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  // After GUI is attached, focus the select element
  afterGuiAttached?(): void {
    this.eSelect.focus();
  }

  // Return the key from the selected option (the one stored in the grid)
  getValue(): any {
    return this.selectedKey || this.eSelect.value;
  }

  isPopup(): boolean {
    return false; // No need for popup editor
  }
}
