import { AppSettings, AuthMethodRow, authMethodNameMap } from '@/types/writeback';
import { useEffect, useState } from 'react';
import { useWBEStore } from '../store';
import { Toaster, ToastBar } from 'react-hot-toast';
import Login from '@/pages/writeback/setup/auth/login';

const Auth = () => {
  const url = useWBEStore((state) => state.url);

  const [appSettings, setAppSettings] = useState<AppSettings | null>(null);
  const [possibleAuthMethods, setPossibleAuthMethods] = useState<AuthMethodRow[]>([]);

  useEffect(() => {
    if (url) fetchAppSettings();
  }, [url]);

  async function fetchAppSettings() {
    try {
      const response = await fetch(`${url}/api/app-settings`);
      const data = (await response.json()) as AppSettings;
      setAppSettings(data);

      setPossibleAuthMethods(
        (data?.settings.auth_method || []).map((method) => ({
          value: method,
          label: authMethodNameMap[method],
        }))
      );
    } catch (error) {
      console.error('Error while fetching app settings:', error);
    }
  }

  return (
    <div className="flex h-full w-full flex-1 flex-col justify-start gap-4 overflow-hidden p-2 font-karla text-lg text-black">
      <h1 className="text-center text-2xl">InputTables</h1>
      <Toaster
        gutter={1}
        containerStyle={{ bottom: 60, right: 50 }}>
        {(t) => <ToastBar toast={t} />}
      </Toaster>
      <img
        className="mx-auto"
        src="./images/ST_WBE.png"
        height={80}
        width={80}
      />
      {url ? (
        <Login
          possibleAuthMethods={possibleAuthMethods}
          appSettings={appSettings}
        />
      ) : (
        <div className="m-3">
          <span className="font-weight-500 text-red-500">This integration is not yet configured</span>.<br />
          <br />
          Once enabled, it will allow you to edit specific data in your table and add comments to individual cells. If
          this sounds interesting, reach out to your dashboard designer to activate this powerful feature.
        </div>
      )}
    </div>
  );
};

export default Auth;
