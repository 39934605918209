import { Badge } from '@/components/ui/badge';
import { useState } from 'react';

const MenuButton = ({
  icon,
  onClick,
  badge,
  title,
  shouldRotate,
}: {
  icon: any;
  onClick?: () => void;
  badge?: number;
  title?: string;
  shouldRotate?: boolean;
}) => {
  const [isRotating, setIsRotating] = useState(false);

  const handleClick = () => {
    if (shouldRotate) {
      setIsRotating(true);
      if (onClick) onClick();

      setTimeout(() => {
        setIsRotating(false);
      }, 1000);
    } else {
      if (onClick) onClick();
    }
  };

  return (
    <div
      className={`relative flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-white p-1 transition-transform duration-1000`}
      onClick={handleClick}
      title={title}>
      <div className={isRotating ? 'rotate-360' : ''}>{icon}</div>
      {badge ? (
        <div className="absolute right-0 top-0 z-50 -translate-y-2 translate-x-3 transform">
          <Badge>{badge}</Badge>
        </div>
      ) : null}
    </div>
  );
};

export default MenuButton;
