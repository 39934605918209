import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { QueryClientProvider } from '@tanstack/react-query';
import Account from './account';
import Audits from './audits';
import Auth from './auth';
import Comment from './comment';
import { WBEProvider } from './context';
import { queryClient } from './queryClient';
import { useWBEStore } from './store';
import { Toaster, ToastBar } from 'react-hot-toast';

export type TabsType = 'Comments' | 'Audits' | 'Account';

const tabs: {
  [key in TabsType]: React.ComponentType;
} = { Comments: Comment, Audits: Audits, Account: Account };

const WriteBackExtreme = () => {
  const selectedTab = useWBEStore((state) => state.selectedTab);
  const token = useWBEStore((state) => state.token);
  const url = useWBEStore((state) => state.url);

  const TabComponent = tabs[selectedTab];

  function handleValueChange(value) {
    useWBEStore.setState({ selectedTab: value as TabsType });
  }

  if (!token || !url) return <Auth />;

  return (
    <QueryClientProvider client={queryClient}>
      <WBEProvider>
        <div className="flex h-full flex-col items-center text-black">
          <Toaster
            gutter={1}
            containerStyle={{ bottom: 60, right: 50 }}>
            {(t) => <ToastBar toast={t} />}
          </Toaster>
          <Tabs
            defaultValue="Comments"
            className="flex h-full w-full flex-1 flex-col overflow-y-hidden"
            value={selectedTab}>
            <TabsList className="overflow-x-auto bg-gray-50 font-montserrat text-lg font-medium">
              {Object.keys(tabs).map((title) => (
                <TabsTrigger
                  key={title}
                  value={title}
                  onClick={() => handleValueChange(title as TabsType)}
                  className="bg-white py-2">
                  {title}
                </TabsTrigger>
              ))}
            </TabsList>
            <TabsContent
              className="flex-1 overflow-y-hidden pt-2 font-karla"
              key={selectedTab}
              value={selectedTab}>
              <TabComponent />
            </TabsContent>
          </Tabs>
        </div>
      </WBEProvider>
    </QueryClientProvider>
  );
};

export default WriteBackExtreme;
