import { ColDef, ColGroupDef, Column, ITooltipParams } from 'ag-grid-community';
import { compileCalculation } from './calculation';
import { TooltipFor, TooltipType } from '@/pages/tooltip/TooltipConfig';
import { FormulaType } from '@/components/formula/FormulaBuilder';
import { Calculation } from '@/types/mapping';
import { debounce } from '@/utils/functional';
import { Settings } from '@/settings';
import { Column as TableauColumn } from '@tableau/extensions-api-types';
import { isCalculation } from './mapping/utils';
import { isDate } from 'date-fns';
import { FormulaType } from '@/types/settings';

class TooltipClass {
  tooltips: TooltipType[];
  columnsToTooltips: { [key: string]: TooltipType };
  rowTooltip: TooltipType | null;
  settings: Settings | null;
  mousePosition: { x: number; y: number };
  rowIndex: number | null;
  debouncedHideWorksheetTooltip: () => void;

  constructor() {
    this.tooltips = [];
    this.columnsToTooltips = {};
    this.rowTooltip = null;
    this.settings = null;
    this.mousePosition = { x: 0, y: 0 };
    this.rowIndex = null;

    this.trackMousePosition = this.trackMousePosition.bind(this);
    this.hideWorksheetTooltip = this.hideWorksheetTooltip.bind(this);
    this.debouncedHideWorksheetTooltip = debounce(this.hideWorksheetTooltip, 200);
    this.mousePosition = { x: 0, y: 0 };
    this.rowIndex = null;

    this.trackMousePosition = this.trackMousePosition.bind(this);
    this.hideWorksheetTooltip = this.hideWorksheetTooltip.bind(this);
    this.debouncedHideWorksheetTooltip = debounce(this.hideWorksheetTooltip, 200);
  }

  clear() {
    this.tooltips = [];
    this.columnsToTooltips = {};
    this.rowTooltip = null;
    this.settings = null;
    this.mousePosition = { x: 0, y: 0 };
    this.rowIndex = null;

    document.removeEventListener('mousemove', this.trackMousePosition);
    this.mousePosition = { x: 0, y: 0 };
    this.rowIndex = null;

    document.removeEventListener('mousemove', this.trackMousePosition);
  }

  init(tooltips: TooltipType[], settings: Settings) {
    console.time('init tooltips');
    this.clear();
    this.tooltips = tooltips;
    this.settings = settings;

    for (const tooltip of tooltips) {
      if (tooltip.type === 'row') {
        this.rowTooltip = tooltip;
      } else {
        for (const column of tooltip.columns) {
          if (isCalculation(column)) {
            this.columnsToTooltips[column.index] = tooltip;
          } else {
            this.columnsToTooltips[column.name] = tooltip;
          }
        }
      }
    }
    console.timeEnd('init tooltips');
    document.addEventListener('mousemove', this.trackMousePosition);
  }

  trackMousePosition(e: MouseEvent) {
    this.mousePosition = { x: e.clientX, y: e.clientY };
    if (!this.settings?.useTableauTooltip) {
      this.debouncedHideWorksheetTooltip();
    }
  }

  getTablePosition() {
    const table = document.querySelector('.ag-center-cols-container');
    if (!table) {
      return undefined;
    }
    const rect = table.getBoundingClientRect();
    return { top: rect.top, left: rect.left, width: rect.width, height: rect.height };
  }

  isMouseOutsideTable() {
    const table = this.getTablePosition();
    if (!table) return false;

    return (
      Tooltip.mousePosition.x <= table.left ||
      Tooltip.mousePosition.x + 1 >= table.left + table.width ||
      Tooltip.mousePosition.y <= table.top ||
      Tooltip.mousePosition.y >= table.top + table.height
    );
  }

  hideWorksheetTooltip() {
    if (this.isMouseOutsideTable()) {
      const worksheetContent = window.tableau.extensions.worksheetContent;
      if (!!worksheetContent && !this.settings?.useTableauTooltip) {
        const worksheet = worksheetContent.worksheet;
        //Null is being passed here but null is not a valid value for hoverTupleAsync
        //Documentation says that passing an invalide value will hide the tooltip without breaking anything,
        //@ts-ignore -1 does not work for hiding the tooltip, so using null
        worksheet.hoverTupleAsync(null);
      }
    }
  }

  getTooltipByColumnName(name: string) {
    const columnTooltip = this.columnsToTooltips[name];
    return columnTooltip;
  }

  getTooltipsByType(type: TooltipFor) {
    return this.tooltips.filter((tooltip) => tooltip.type === type);
  }

  getRowTooltip() {
    return this.rowTooltip;
  }

  getTooltipStyle() {
    return {
      padding: this.settings?.tooltipPadding ?? 0,
      borderWidth: this.settings?.tooltipBorderWidth ?? 0,
      borderColor: this.settings?.tooltipBorderColor ?? '#000000',
      borderRadius: this.settings?.tooltipBorderRadius ?? 0,
      backgroundColor: this.settings?.tooltipBackgroundColor ?? '#ffffff',
      color: this.settings?.tooltipFontColor ?? '#000000',
    };
  }

  getColumnValue(
    value: string | (FormulaType & Calculation),
    params: ITooltipParams,
    data: {
      [key: string]: any;
    },
    column: Column | null
  ): string {
    let finalValue = value;

    if (typeof value === 'object' && !isDate(value)) {
      // calculation: value is an object with a calculation details
      const calculationColumn = value;
      const allColumns: Array<TableauColumn> = params.context.tableauColumns;
      const calc = compileCalculation(calculationColumn, allColumns);
      finalValue = calc(data) as string;
    }
    if (column) {
      const colDef = column.getColDef();
      // @ts-ignore -> TODO: make a union type for tooltipFormatter with ColDef
      const tooltipFormatter = colDef?.tooltipFormatter;
      if (tooltipFormatter) {
        const formattedValue: string = tooltipFormatter({ value, type: 'tooltip' });
        finalValue = formattedValue ?? value;
      }
    }
    return finalValue;
  }

  getTooltipData(tooltipHTML: string, params: ITooltipParams) {
    const parser = new DOMParser();
    const html = parser.parseFromString(tooltipHTML, 'text/html');
    const rowIndex = params.rowIndex as number;
    const data = params?.api?.getDisplayedRowAtIndex(rowIndex)?.data;

    // these are column mentions where the columnName has to be replaced with the value
    // example element: <span data-type="columnMention" class="mention mention-columns" data-id="(Profit)">@(Profit)</span>
    const columnElements = html.querySelectorAll('span[data-type="columnMention"]');
    columnElements.forEach((element) => {
      const columnName = element.getAttribute('data-id')?.trim() as string;
      const columnValue = data[columnName];
      if (columnValue) {
        const column = params.api.getColumn(columnName);
        const value = this.getColumnValue(columnValue, params, data, column); // gets you the formatted value
        element.textContent = value; // replace @(Profit) with the value
      }
    });

    // these are self mentions where the element has to be replaced with the value of the current column
    // example element: <span data-type="selfMention" class="mention mention-self" data-id="Column Name">#Column Name</span>
    // example element: <span data-type="selfMention" class="mention mention-self" data-id="Column Value">#Column Value</span>
    const selfElements = html.querySelectorAll('span[data-type="selfMention"]');
    selfElements.forEach((element) => {
      const id = element.getAttribute('data-id')?.trim() as string;
      if (id === 'Column Name') {
        element.textContent = params?.colDef?.headerName ?? (params?.colDef as ColDef)?.field ?? '';
      } else {
        let value = params.valueFormatted ?? params.value;
        element.textContent = value ?? '';
      }
    });

    const imageElements = html.querySelectorAll('span.image-column');
    imageElements.forEach((element) => {
      const columnName = element.getAttribute('data-id')?.trim() as string;
      const columnValue = data[columnName]?.trim();
      if (columnValue) {
        const maxWidth = element.getAttribute('data-width') as string;
        const maxHeight = element.getAttribute('data-height') as string;
        const url = element.getAttribute('data-url') as string;
        const value = url.replace('@' + columnName, columnValue);
        const encodedValue = encodeURI(value);
        element.innerHTML = `<img src="${encodedValue}" alt="${value}" style="max-width: ${maxWidth}; max-height: ${maxHeight}; object-fit: contain;" />`;
      }
    });

    return html.body.innerHTML;
  }
}

export const Tooltip = new TooltipClass();

export default class TooltipGrid {
  eGui: HTMLDivElement | undefined;
  init(params: ITooltipParams) {
    const eGui = (this.eGui = document.createElement('div'));
    const extension = window.tableau.extensions;
    // VizExtension
    if (!!extension.worksheetContent && !Tooltip?.settings?.useTableauTooltip) {
      Tooltip.rowIndex = params?.data?.index ? params.data.index - 1 : null;
      return;
    }

    // init function is called for every new row.
    const isHeader = params.rowIndex === undefined;
    const isGroupedHeader = isHeader && !!(params.colDef as ColGroupDef)?.children;

    eGui.classList.add('custom-tooltip');
    eGui.classList.add('tiptap');

    if (!Tooltip?.settings?.userCanCopy) {
      eGui.style.userSelect = 'none';
    }

    let tooltipContent = '';

    if (isHeader) {
      tooltipContent = `<p> ${params.value}</p>`;
      if (isGroupedHeader) {
        tooltipContent += '<hr>';
        (params.colDef as ColGroupDef)?.children.forEach(function (header, idx) {
          tooltipContent += '<p>Child ' + (idx + 1) + ' - ' + header.headerName + '</p>';
        });
      }
    } else {
      let columnName = (params?.colDef as ColDef)?.field ?? '';
      if (columnName === '') {
        // this is a group column
        tooltipContent = `<p><b>${params.value}</b></p>`;
      } else {
        const tooltip = Tooltip.getTooltipByColumnName(columnName);
        const generalTooltip = Tooltip.getRowTooltip();
        if (!tooltip) {
          // no specific tooltip for this column, show the general tooltip
          if (generalTooltip) {
            tooltipContent = Tooltip.getTooltipData(generalTooltip.content, params);
          }
        } else {
          const tooltipHTML = Tooltip.getTooltipData(tooltip.content, params);
          tooltipContent = tooltipHTML;
          if (tooltip.mode !== 'override') {
            if (generalTooltip) {
              const generalTooltipContent = Tooltip.getTooltipData(generalTooltip.content, params);
              if (tooltip.mode === 'append') {
                tooltipContent = `<div> ${generalTooltipContent}</div><div> ${tooltipHTML}</div>`;
              } else {
                tooltipContent = `<div> ${tooltipHTML}</div><div> ${generalTooltipContent}</div>`;
              }
            }
          }
        }
      }
    }

    if (tooltipContent === '') {
      // no tooltip, hide the tooltip
      eGui.style.display = 'none';
      return;
    } else {
      const tooltipStyle = Tooltip.getTooltipStyle();
      eGui.style.borderWidth = `${tooltipStyle.borderWidth}px`;
      eGui.style.borderRadius = `${tooltipStyle.borderRadius}px`;
      eGui.style.borderColor = tooltipStyle.borderColor;
      eGui.style.backgroundColor = tooltipStyle.backgroundColor;
      eGui.style.color = tooltipStyle.color;
      eGui.style.padding = `${tooltipStyle.padding}px`;
      eGui.style.borderStyle = 'solid';
      eGui.style.boxShadow = '1px 2px 1px 0 rgba(0, 0, 0, 0.2)';

      eGui.innerHTML = tooltipContent;
    }
  }

  getGui() {
    const worksheetContent = window.tableau.extensions.worksheetContent;
    if (!!worksheetContent && !Tooltip?.settings?.useTableauTooltip) {
      const worksheet = worksheetContent.worksheet;
      const tupleId = Tooltip?.rowIndex != null ? Tooltip.rowIndex + 1 : null;
      if (tupleId) {
        worksheet.hoverTupleAsync(tupleId, {
          tooltipAnchorPoint: { x: Tooltip.mousePosition.x, y: Tooltip.mousePosition.y },
        });
      }
    }

    return this.eGui;
  }
}
