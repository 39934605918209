import React from 'react';
import { createRoot } from 'react-dom/client';
import WriteBackExtreme from '.';

export default class WriteBackExtremeToolPanel {
  eGui: HTMLDivElement | null = null;

  init() {
    this.eGui = document.createElement('div');
    this.eGui.style.height = '100%';
    this.eGui.style.width = '100%';

    const App: React.FC = () => {
      return <WriteBackExtreme />;
    };

    setTimeout(() => {
      const root = createRoot(this.eGui!);
      root.render(<App />);
    }, 1000);
  }

  getGui() {
    return this.eGui;
  }

  refresh() {}
}
