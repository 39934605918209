import React, { Component, createRef } from 'react';
import { AgPromise, ICellEditorComp, ICellEditorParams } from 'ag-grid-community';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';
import { Calendar } from '@/components/ui/calendar'; // Your custom Calendar component

interface DateTimeEditorProps {
  value?: string;
  onValueChanged: (newValue: string) => void;
  pickerType: 'date' | 'datetime'; // Prop to decide between DatePicker and DateTimePicker
}

interface DateTimeEditorState {
  value: string; // The combined date and time value
  selectedDate: Date | null; // Stores the selected date
  selectedTime: { hours: number; minutes: number }; // Stores the selected time
  isCalendarOpen: boolean;
  inputPosition: { top: number; left: number; width: number } | null;
}

export default class DatePicker implements ICellEditorComp {
  private eGui!: HTMLElement;
  private currentValue: string = '';
  private params!: ICellEditorParams;

  init(params: ICellEditorParams<any, any, any>): AgPromise<void> | void {
    this.params = params;
    const element = document.createElement('div');

    const onValueChanged = (newValue: string) => {
      this.currentValue = newValue;
      this.params.stopEditing(); // Stop editing after the value is set
    };

    const root = createRoot(element);
    root.render(
      <DateTimeEditor
        value={params.value}
        onValueChanged={onValueChanged}
        pickerType={params.pickerType || 'datetime'}
        format={params.format} // Pass the pickerType prop to the editor
      />
    );

    this.eGui = element;
  }

  getValue() {
    const { pickerType, format } = this.params;

    if (pickerType === 'date') {
      // Return only the date in 'yyyy-MM-dd' format
      return this.currentValue ? new Date(this.currentValue.slice(0, 10)) : null;
    }

    // For 'datetime', return the full datetime string
    return this.currentValue;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  afterGuiAttached?(): void {
    // Optional: focus or perform other actions when the editor is attached
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }
}

class DateTimeEditor extends Component<DateTimeEditorProps, DateTimeEditorState> {
  inputRef: React.RefObject<HTMLInputElement>;
  calendarRef: React.RefObject<HTMLDivElement>;

  constructor(props: DateTimeEditorProps) {
    super(props);

    const initialDate = props?.value ? new Date(props.value) : null;
    const initialHours = initialDate ? initialDate.getUTCHours() : 12; // Default to 12:00 UTC
    const initialMinutes = initialDate ? initialDate.getUTCMinutes() : 0;

    this.state = {
      value: props?.value || '',
      selectedDate: initialDate,
      selectedTime: { hours: initialHours, minutes: initialMinutes },
      isCalendarOpen: false,
      inputPosition: null,
    };

    this.inputRef = createRef();
    this.calendarRef = createRef();
  }

  toggleCalendar = () => {
    if (!this.state.isCalendarOpen && this.inputRef.current) {
      const inputRect = this.inputRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const calendarHeight = 300; // Adjust this value based on the calendar's height

      // Calculate the space available above and below the input
      const spaceBelow = viewportHeight - inputRect.bottom;
      const spaceAbove = inputRect.top;

      // Determine the new position based on available space
      const shouldShowAbove = spaceBelow < calendarHeight && spaceAbove >= calendarHeight;

      this.setState({
        isCalendarOpen: true,
        inputPosition: {
          top: shouldShowAbove ? inputRect.top - calendarHeight : inputRect.bottom,
          left: inputRect.left,
          width: inputRect.width,
        },
      });
    } else {
      this.setState({ isCalendarOpen: false });
    }
  };

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.inputRef.current &&
      !this.inputRef.current.contains(event.target as Node) &&
      this.calendarRef.current &&
      !this.calendarRef.current.contains(event.target as Node)
    ) {
      this.setState({ isCalendarOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setDate = (selectedDate: Date | null) => {
    if (selectedDate) {
      // Treat the selected date as UTC when setting the date
      const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
      this.setState({ selectedDate: utcDate }, () => {
        // For date picker, update the value immediately
        if (this.props.pickerType === 'date') {
          this.updateValue();
        }
      });
    }
  };

  setTime = (hours: number, minutes: number) => {
    this.setState({ selectedTime: { hours, minutes } }, this.updateValue);
  };

  // Combine date and time into a single value and handle UTC correctly
  updateValue = () => {
    const { selectedDate, selectedTime } = this.state;
    const { pickerType, format } = this.props;

    if (selectedDate) {
      // Create a copy of the selected date
      const combinedDate = new Date(selectedDate);

      if (pickerType === 'datetime') {
        // Set the time for the datetime picker
        combinedDate.setUTCHours(selectedTime.hours); // Use setUTCHours instead of setHours
        combinedDate.setUTCMinutes(selectedTime.minutes); // Use setUTCMinutes instead of setMinutes
      }

      // Format the date based on the pickerType
      const newValue =
        pickerType === 'date'
          ? combinedDate.toISOString().slice(0, 10) // Use toISOString for just date picker and slice to get the date in 2024-10-31 format
          : combinedDate.toISOString().replace('T', ' ').slice(0, 19); // Use ISO string for datetime picker in 2024-10-15 10:00:00 format
      // Update the value in the state and trigger the callback
      this.setState({ value: newValue });
      this.props.onValueChanged(newValue);

      // Close the calendar when pickerType is "date"
      if (pickerType === 'date') {
        this.setState({ isCalendarOpen: false });
      }
    }
  };

  handleSetDateTime = () => {
    this.updateValue();
    this.setState({ isCalendarOpen: false });
  };

  renderCalendarPortal() {
    const { isCalendarOpen, inputPosition, selectedDate, selectedTime } = this.state;
    const { pickerType } = this.props;

    if (!isCalendarOpen || !inputPosition) return null;

    const calendarStyle: React.CSSProperties = {
      position: 'absolute',
      top: `${inputPosition.top - 100}px`,
      left: `${inputPosition.left}px`,
      width: `${inputPosition.width}px`,
      zIndex: 1000,
      backgroundColor: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '10px', // Add padding for better spacing
      overflow: 'auto', // Allow the calendar to overflow the window/iframe if necessary
      maxHeight: 'calc(100vh - 20px)', // Limit the height of the calendar to fit within the window/iframe
    };

    return createPortal(
      <div
        ref={this.calendarRef}
        style={calendarStyle}>
        {/* Calendar for date selection */}
        <Calendar
          mode="single"
          defaultMonth={selectedDate || undefined}
          selected={selectedDate || undefined}
          onSelect={this.setDate}
          showOutsideDays={true}
        />

        {pickerType === 'datetime' && (
          <>
            {/* Time Picker */}
            <div style={{ marginTop: '10px', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  fontSize: '0.7rem', // Changed font size
                  fontWeight: 'bold',
                  marginRight: '5px',
                }}>
                Select Time:
              </span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Hour Selector */}
                <select
                  style={{
                    color: '#ff1493',
                    backgroundColor: 'white',
                    padding: '5px',
                    outline: 'none',
                    fontWeight: 'bold',
                    fontSize: '0.7rem', // Changed font size
                    textAlign: 'center',
                    width: '60px',
                    margin: '0 5px',
                    border: 'none', // Removed border
                  }}
                  value={selectedTime.hours}
                  onChange={(e) => this.setTime(Number(e.target.value), selectedTime.minutes)}>
                  {[...Array(24).keys()].map((hour) => (
                    <option
                      key={hour}
                      value={hour}>
                      {hour.toString().padStart(2, '0')}
                    </option>
                  ))}
                </select>
                <span style={{ color: '#ff1493', fontWeight: 'bold', margin: '0 5px' }}>:</span>
                {/* Minute Selector */}
                <select
                  style={{
                    color: '#ff1493',
                    backgroundColor: 'white',
                    padding: '5px',
                    outline: 'none',
                    fontWeight: 'bold',
                    fontSize: '0.7rem', // Changed font size
                    textAlign: 'center',
                    width: '60px',
                    margin: '0 5px',
                    border: 'none', // Removed border
                  }}
                  value={selectedTime.minutes}
                  onChange={(e) => this.setTime(selectedTime.hours, Number(e.target.value))}>
                  {[0, 15, 30, 45].map((minute) => (
                    <option
                      key={minute}
                      value={minute}>
                      {minute.toString().padStart(2, '0')}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Set Date and Time Button */}
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <button
                onClick={this.handleSetDateTime}
                style={{
                  backgroundColor: '#ff1493',
                  color: 'white',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}>
                Set Date and Time
              </button>
            </div>
          </>
        )}
      </div>,
      document.body
    );
  }

  render() {
    return (
      <div>
        <input
          ref={this.inputRef}
          type={this.props.pickerType === 'date' ? 'date' : 'text'}
          value={this.state.value}
          onClick={this.toggleCalendar}
          readOnly
          style={{
            cursor: 'pointer',
            fontSize: '0.9rem', // Adjust font size for the input field
            border: '1px solid #ccc',
            borderRadius: '4px',
            outline: 'none',
            padding: '50px',
          }}
        />
        {this.renderCalendarPortal()}
      </div>
    );
  }
}
